import { GridFilterModel } from '@mui/x-data-grid-pro';
import { IAPIMarketplaces } from 'src/store/dashboard/slices/myShopsAPITableSlice';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';

type PlatformName =
  | IPlatforms.MERCARI
  | IPlatforms.FACEBOOK
  | IPlatforms.EBAYUS
  | IPlatforms.EBAYCA
  | IPlatforms.EBAYAU
  | IPlatforms.EBAYAT
  | IPlatforms.EBAYBE
  | IPlatforms.EBAYCH
  | IPlatforms.EBAYDE
  | IPlatforms.EBAYES
  | IPlatforms.EBAYFR
  | IPlatforms.EBAYGB
  | IPlatforms.EBAYHK
  | IPlatforms.EBAYIE
  | IPlatforms.EBAYIN
  | IPlatforms.EBAYIT
  | IPlatforms.EBAYMY
  | IPlatforms.EBAYNL
  | IPlatforms.EBAYPH
  | IPlatforms.EBAYPL
  | IPlatforms.EBAYSG
  | IPlatforms.EBAYTW
  | IPlatforms.EBAYVN
  | IPlatforms.ETSY
  | IPlatforms.DEPOP
  | IPlatforms.POSHMARKUS
  | IPlatforms.POSHMARKCA
  | IPlatforms.GRAILED
  | IPlatforms.SHOPIFY
  | '';

const getPlatformName = (platform: string): PlatformName => {
  switch (platform) {
    case 'Mercari':
      return IPlatforms.MERCARI;
    case 'Facebook':
      return IPlatforms.FACEBOOK;
    case 'eBay (US)':
      return IPlatforms.EBAYUS;
    case 'eBay (CA)':
      return IPlatforms.EBAYCA;
    case 'eBay (AU)':
      return IPlatforms.EBAYAU;
    case 'eBay (AT)':
      return IPlatforms.EBAYAT;
    case 'eBay (BE)':
      return IPlatforms.EBAYBE;
    case 'eBay (CH)':
      return IPlatforms.EBAYCH;
    case 'eBay (DE)':
      return IPlatforms.EBAYDE;
    case 'eBay (ES)':
      return IPlatforms.EBAYES;
    case 'eBay (FR)':
      return IPlatforms.EBAYFR;
    case 'eBay (GB)':
      return IPlatforms.EBAYGB;
    case 'eBay (HK)':
      return IPlatforms.EBAYHK;
    case 'eBay (IE)':
      return IPlatforms.EBAYIE;
    case 'eBay (IN)':
      return IPlatforms.EBAYIN;
    case 'eBay (IT)':
      return IPlatforms.EBAYIT;
    case 'eBay (MY)':
      return IPlatforms.EBAYMY;
    case 'eBay (NL)':
      return IPlatforms.EBAYNL;
    case 'eBay (PH)':
      return IPlatforms.EBAYPH;
    case 'eBay (PL)':
      return IPlatforms.EBAYPL;
    case 'eBay (SG)':
      return IPlatforms.EBAYSG;
    case 'eBay (TW)':
      return IPlatforms.EBAYTW;
    case 'eBay (VN)':
      return IPlatforms.EBAYVN;
    case 'Etsy':
      return IPlatforms.ETSY;
    case 'Depop':
      return IPlatforms.DEPOP;
    case 'Poshmark (US)':
      return IPlatforms.POSHMARKUS;
    case 'Poshmark (CA)':
      return IPlatforms.POSHMARKCA;
    case 'Grailed':
      return IPlatforms.GRAILED;
    case 'Shopify':
      return IPlatforms.SHOPIFY;
    default:
      return '';
  }
};

/**                                           !!! ATTENTION !!!
 *        Prior to working on this file, it is crucial to disable prettier or any other code formatter
 *        to prevent inadvertently compromising the handling of certain exceptional filter cases,
 *        such as on line 91 and 114.
 */

//this function takes the DataGridModel and converts into desired shape for api request
export const getTasksFilterModelShaped = (model: GridFilterModel): string | undefined => {
  const hasValidFilters = model.items.every((item) => {
    if (item.operatorValue === 'isEmpty' || item.operatorValue === 'isNotEmpty') {
      return item.value === undefined;
    } else {
      return item.value !== undefined;
    }
  });
  if (!hasValidFilters) {
    return undefined;
  }

  const multiFilters: any[] = [];

  //checks for the specific rules according to every filter field
  model.items.forEach((item) => {
    let { value } = item;
    //if value comes as an array
    if (Array.isArray(value)) {
      value = value.map((val) => {
        if (item.columnField === 'refs') {
          const platform = getPlatformName(val);
          return `${platform}`;
        } else if (item.columnField === 'executeAt') {
          return val.length ? new Date(val).getTime() : null;
        } else if (item.columnField === 'createdDate') {
          return val.length ? new Date(val) : null;
        } else if (item.columnField === 'price') {
          return Number(val);
        } else {
          return val.replace(/[\W_]/g, (match: any) => {
            if (match === '"') {
              /* prettier-ignore */
              // eslint-disable-next-line no-useless-escape
              return '\"';
            } else {
              return match;
            }
          });
        }
      });
      //if value does not comes as an array
    } else {
      if (item.operatorValue === 'isEmpty' || item.operatorValue === 'isNotEmpty') {
        value = '';
      } else if (item.columnField === 'refs') {
        value = getPlatformName(value);
      } else if (item.columnField === 'executeAt') {
        value = value.length ? new Date(value).getTime() : null;
      } else if (item.columnField === 'createdDate') {
        value = value.length ? new Date(value) : null;
      } else if (item.columnField === 'price') {
        value = Number(value);
      } else {
        if (value.includes('#') || value.includes('"')) {
          value = value.replace(/[\W_]/g, (match: any) => {
            if (match === '"') {
              /* prettier-ignore */
              // eslint-disable-next-line no-useless-escape
              return '\"';
            } else {
              return match;
            }
          });
        }
      }
    }

    const filterObj: { field: string; operator: any; value?: any } = {
      field: item.columnField,
      operator:
        // Check if the column field is 'price' and the operator value is a string with letters
        item.columnField.toLowerCase() === 'price' && /[a-zA-Z]/.test(item?.operatorValue!)
          ? // Create the operator string for numeric filters
            `numeric${item?.operatorValue?.slice(0, 1).toUpperCase()}${item?.operatorValue?.slice(
              1
            )}`
          : item?.operatorValue,
      value,
    };

    multiFilters.push(filterObj);
  });

  const ifOperatorIsNotSpecialControl = (item: any) =>
    !['isEmpty', 'isNotEmpty', 'numericIsEmpty', 'numericIsNotEmpty'].includes(item.operator);

  const ifValueIsArrayControl = (item: any) =>
    Array.isArray(item.value) &&
    ((item.operator === 'between' &&
      (item.value.length === 0 || item.value[0] == null || item.value[1] == null)) ||
      item.value.length === 0);

  const nameFieldControl = (item: any) =>
    item.field === 'name' && (item.value === null || item.value === '');

  const dateFieldsControl = (item: any) =>
    (item.field === 'createdDate' || item.field === 'executeAt') &&
    item.operator === 'between' &&
    (item.value === null || item.value === undefined || item.value === '');

  const filtered = {
    filters: multiFilters.filter((item) => {
      if (ifOperatorIsNotSpecialControl(item)) {
        if (Array.isArray(item.value)) {
          if (ifValueIsArrayControl(item)) {
            return false;
          } else {
            return true;
          }
        } else if (nameFieldControl(item)) {
          return false;
        } else if (dateFieldsControl(item)) {
          return false;
        }
      }

      return true;
    }),
    parentOperator: model.linkOperator,
  };

  const controlledFilter = filtered.filters.length > 0 ? JSON.stringify(filtered) : '';

  return controlledFilter;
};
