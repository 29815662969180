import { Automation, UserAction } from '@beta.limited/primelister';
import { IActivityItem } from './types';
import { AutomationPlatform } from 'src/hooks/useAutomationPlatform';

export enum CustomAutomationWidgetType {
  CUSTOM_PRICE_UPDATE = 'custom-price-update',
  CUSTOM_OFFER_TO_LIKERS = 'custom-offer-to-likers',
  CUSTOM_RELIST = 'custom-relist',
}

export const customTaskItemsList: IActivityItem[] = [
  {
    value: CustomAutomationWidgetType.CUSTOM_PRICE_UPDATE,
    name: 'priceUpdate',
    displayName: 'Price Update',
    autoIcon: 'priceUpdate',
    urlParam: CustomAutomationWidgetType.CUSTOM_PRICE_UPDATE,
    widgetMatcher: CustomAutomationWidgetType.CUSTOM_PRICE_UPDATE,
  },
  {
    value: CustomAutomationWidgetType.CUSTOM_OFFER_TO_LIKERS,
    name: 'autoSendOfferToLikers',
    displayName: 'Offer to Likers',
    autoIcon: 'offerToLikers',
    urlParam: CustomAutomationWidgetType.CUSTOM_OFFER_TO_LIKERS,
    widgetMatcher: CustomAutomationWidgetType.CUSTOM_OFFER_TO_LIKERS,
  },
  {
    value: CustomAutomationWidgetType.CUSTOM_RELIST,
    name: 'autoRelist',
    displayName: 'Re-list',
    autoIcon: 'relist',
    urlParam: CustomAutomationWidgetType.CUSTOM_RELIST,
    widgetMatcher: CustomAutomationWidgetType.CUSTOM_RELIST,
  },
];

export const completedTaskItemsList: IActivityItem[] = [
  {
    value: CustomAutomationWidgetType.CUSTOM_PRICE_UPDATE,
    name: 'priceUpdate',
    displayName: 'Custom Price Update',
    autoIcon: 'priceUpdate',
    urlParam: CustomAutomationWidgetType.CUSTOM_PRICE_UPDATE,
    widgetMatcher: CustomAutomationWidgetType.CUSTOM_PRICE_UPDATE,
  },
  {
    value: CustomAutomationWidgetType.CUSTOM_OFFER_TO_LIKERS,
    name: 'offerToLikers',
    displayName: 'Custom Offer to Likers',
    autoIcon: 'autoSendOfferToLikers',
    urlParam: CustomAutomationWidgetType.CUSTOM_OFFER_TO_LIKERS,
    widgetMatcher: CustomAutomationWidgetType.CUSTOM_OFFER_TO_LIKERS,
  },
  {
    value: CustomAutomationWidgetType.CUSTOM_RELIST,
    name: 'relist',
    displayName: 'Custom Re-list',
    autoIcon: 'autoRelist',
    urlParam: CustomAutomationWidgetType.CUSTOM_RELIST,
    widgetMatcher: CustomAutomationWidgetType.CUSTOM_RELIST,
  },
];

export const automationItemsList: IActivityItem[] = [
  {
    value: 'all',
    name: 'allActivities',
    displayName: 'All Activities',
    autoIcon: 'allActivities',
    urlParam: 'all-activities',
  },
  {
    value: Automation.AUTO_CLOSET_SHARE,
    name: 'autoClosetShare',
    displayName: 'Share',
    autoIcon: 'share',
    urlParam: 'share',
    widgetMatcher: 'auto-closet-share',
  },
  {
    value: Automation.AUTO_COMMUNITY_SHARE,
    name: 'autoCommunityShare',
    displayName: 'Community Share',
    autoIcon: 'autoCommunityShare',
    urlParam: 'community-share',
    widgetMatcher: 'auto-community-share',
  },
  {
    value: Automation.AUTO_RELIST,
    name: 'autoRelist',
    displayName: 'Re-list',
    autoIcon: 'relist',
    urlParam: 'relist',
    widgetMatcher: 'auto-relist',
  },
  {
    value: Automation.AUTO_SEND_OFFER_TO_LIKERS,
    name: 'autoSendOfferToLikers',
    displayName: 'Offer to Likers',
    autoIcon: 'offerToLikers',
    urlParam: 'offer-to-likers',
    widgetMatcher: 'auto-send-offer-to-likers',
  },
  {
    value: Automation.AUTO_PARTY_SHARE,
    name: 'autoPartyShare',
    displayName: 'Party Share',
    autoIcon: 'partyShare',
    urlParam: 'party-share',
    widgetMatcher: 'auto-party-share',
  },
  {
    value: Automation.RETURN_SHARE,
    name: 'returnShare',
    displayName: 'Return Share',
    autoIcon: 'returnShare',
    urlParam: 'return-share',
    widgetMatcher: 'return-share',
  },
  {
    value: Automation.RETURN_FOLLOW,
    name: 'returnFollow',
    displayName: 'Return Follow',
    autoIcon: 'returnFollow',
    urlParam: 'return-follow',
    widgetMatcher: 'return-follow',
  },
  {
    value: Automation.AUTO_FOLLOW_NEW_CLOSETS,
    name: 'autoFollowFreshClosets',
    displayName: 'Follow New Closets',
    autoIcon: 'followFreshClosets',
    urlParam: 'follow-new-closets',
    widgetMatcher: 'auto-follow-new-closets',
  },
];

export const completedTabItemsList: IActivityItem[] = [
  {
    value: 'all',
    name: 'allActivities',
    displayName: 'All Activities',
    autoIcon: 'allActivities',
    urlParam: 'all-activities',
  },
  {
    value: Automation.AUTO_CLOSET_SHARE,
    name: 'share',
    displayName: 'Share',
    autoIcon: 'autoClosetShare',
    urlParam: 'share',
    widgetMatcher: 'auto-closet-share',
  },
  {
    value: Automation.AUTO_COMMUNITY_SHARE,
    name: 'autoCommunityShare',
    displayName: 'Community Share',
    autoIcon: 'autoCommunityShare',
    urlParam: 'community-share',
    widgetMatcher: 'auto-community-share',
  },
  {
    value: Automation.AUTO_RELIST,
    name: 'relist',
    displayName: 'Re-list',
    autoIcon: 'autoRelist',
    urlParam: 'relist',
    widgetMatcher: 'auto-relist',
  },
  {
    value: Automation.AUTO_SEND_OFFER_TO_LIKERS,
    name: 'offerToLikers',
    displayName: 'Offer to Likers',
    autoIcon: 'autoSendOfferToLikers',
    urlParam: 'offer-to-likers',
    widgetMatcher: 'auto-send-offer-to-likers',
  },
  {
    value: Automation.AUTO_PARTY_SHARE,
    name: 'partyShare',
    displayName: 'Party Share',
    autoIcon: 'autoPartyShare',
    urlParam: 'party-share',
    widgetMatcher: 'auto-party-share',
  },
  {
    value: Automation.RETURN_SHARE,
    name: 'returnShare',
    displayName: 'Return Share',
    autoIcon: 'returnShare',
    urlParam: 'return-share',
    widgetMatcher: 'return-share',
  },
  {
    value: Automation.RETURN_FOLLOW,
    name: 'returnFollow',
    displayName: 'Return Follow',
    autoIcon: 'returnFollow',
    urlParam: 'return-follow',
    widgetMatcher: 'return-follow',
  },
  {
    value: Automation.AUTO_FOLLOW_NEW_CLOSETS,
    name: 'followFreshClosets',
    displayName: 'Follow New Closets',
    autoIcon: 'autoFollowFreshClosets',
    urlParam: 'follow-new-closets',
    widgetMatcher: 'auto-follow-new-closets',
  },
  ...completedTaskItemsList,
];

const ongoingTaskItemsList: IActivityItem[] = [
  {
    value: UserAction.UPDATE,
    name: 'priceUpdate',
    displayName: 'Custom Price Update',
    autoIcon: 'priceUpdate',
    urlParam: 'price-update',
    widgetMatcher: UserAction.UPDATE,
  },
  {
    value: UserAction.OFFER,
    name: 'autoSendOfferToLikers',
    displayName: 'Custom Offer to Likers',
    autoIcon: 'offerToLikers',
    urlParam: 'offer-to-likers',
    widgetMatcher: UserAction.OFFER,
  },
  {
    value: UserAction.RELIST,
    name: 'autoRelist',
    displayName: 'Custom Re-list',
    autoIcon: 'relist',
    urlParam: 'relist',
    widgetMatcher: UserAction.RELIST,
  },
];

export const ongoingTabItemsList: IActivityItem[] = [
  automationItemsList[0],
  ...ongoingTaskItemsList,
];

export const ACTIVITY_REQUEST_TYPE = {
  newRequest: 'new' as 'new',
  moreActivityRequest: 'more' as 'more',
};

export const ebayCompletedTabItemsList: IActivityItem[] = [
  {
    value: 'all',
    name: 'allActivities',
    displayName: 'All Activities',
    autoIcon: 'allActivities',
    urlParam: 'all-activities',
  },
  {
    value: 'auto-relist',
    name: 'ebay-auto-relist',
    displayName: 'Re-create',
    autoIcon: 'autoRelist',
    urlParam: 'auto-relist',
    widgetMatcher: 'auto-relist',
  },
  {
    value: 'auto-send-offer-to-likers',
    name: 'offerToLikers',
    displayName: 'Offer to Watchers',
    autoIcon: 'autoSendOfferToLikers',
    urlParam: 'auto-send-offer-to-likers',
    widgetMatcher: 'auto-send-offer-to-likers',
  },
];

export const ebayAutomationItemsList: IActivityItem[] = [
  {
    value: Automation.AUTO_RELIST,
    name: 'autoRelist',
    displayName: 'Re-create',
    autoIcon: 'relist',
    urlParam: 'relist',
    widgetMatcher: 'ebay-auto-relist',
  },
  {
    value: Automation.AUTO_SEND_OFFER_TO_LIKERS,
    name: 'autoSendOfferToLikers',
    displayName: 'Offer to Watchers',
    autoIcon: 'offerToLikers',
    urlParam: 'offer-to-likers',
    widgetMatcher: 'ebay-auto-send-offer-to-likers',
  },
];

export const getItemsList = (platform: AutomationPlatform) =>
  platform === AutomationPlatform.EBAY ? ebayCompletedTabItemsList : completedTabItemsList;
