import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { IRootState } from 'src/store';
import { setStripeReferrerUrl } from 'src/store/dashboard/slices/pricingSlice';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { SubscriptionStatus } from 'src/store/dashboard/slices/accountSlice';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getPlatformActions } from 'src/utils/platformActions';

const UpgradeSubscriptionDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);

  const { isUpgradeSubscriptionDialogOpen } = states.myShops;

  const { poshmarkShopCapacity, ebayShopCapacity, billingSubscriptionList } = useSelector(
    (state: IRootState) => state.account
  );

  const handleClose = () => dispatch(platformActions.setIsUpgradeSubscriptionDialogOpen(false));

  const handleUpgrade = () => {
    dispatch(setStripeReferrerUrl(window.location.href));
    const subscriptionPath = platform === AutomationPlatform.EBAY 
      ? PATH_DASHBOARD.subscription.ebayAutomation 
      : PATH_DASHBOARD.subscription.poshmarkAutomation;
    navigate(subscriptionPath + '?action=upgrade');
    handleClose();
  };

  const shopCapacity = platform === AutomationPlatform.EBAY ? ebayShopCapacity : poshmarkShopCapacity;
  const shopText = platform === AutomationPlatform.EBAY ? 'shops' : 'closets';
  const singleShopText = platform === AutomationPlatform.EBAY ? 'shop' : 'closet';

  const isTrial = billingSubscriptionList[1]?.status?.toLowerCase() === SubscriptionStatus.TRIAL;
  const isLifeTime = billingSubscriptionList[1]?.planId === 'poshmark-plan-lifetime';
  const isTrialDialog = isTrial || isLifeTime;

  const buttonText = isTrialDialog ? 'Subscribe' : 'Upgrade My Subscription';
  const initialText = isTrialDialog
    ? `Your trial subscription allows you to connect 1 ${singleShopText}, and you've already reached that limit.`
    : `Your current subscription allows up to ${shopCapacity} ${shopCapacity > 1 ? shopText : singleShopText} connected, and you've already reached that limit.`;

  const confirmationMessage = [
    initialText,
    `To unlock full potential and access powerful automations for additional ${shopText}, please upgrade your subscription and benefit from advanced features tailored to your growing needs.`,
  ];

  return (
    <Dialog fullWidth maxWidth="sm" open={isUpgradeSubscriptionDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 3 }}>Upgrade Your Subscription: Action Required</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        {confirmationMessage.map((message, index) => (
          <Typography
            sx={{
              ...(index < 1 && { pb: 3 }),
              fontSize: '16px',
              fontWeight: '400',
              color: '#637381',
            }}
            key={index}
          >
            {message}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleUpgrade}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeSubscriptionDialog;
