import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { crosslistEndpoints, poshmarkAutomationEndpoints } from 'src/endpoints';
import axiosInstance from 'src/utils/axios';
import {
  getShopsListSuccess,
  getShopsListFail,
  getShopsListRequest,
  removeClosetSuccess,
  removeClosetFail,
  removeClosetRequest,
  IRemoveData,
  setActiveCloset,
  ConnectionStatus,
  addEbayShopRequest,
  addEbayShopSuccess,
  addEbayShopFail,
  getEbayAuthUrlRequest,
  getEbayAuthUrlSuccess,
  getEbayAuthUrlFail,
} from '../slices/myShopsSlice.ebay';
import { toast } from 'react-toastify';
import { sortClosetListAccordingToStatus } from 'src/pages/automations/MyClosetPage/helpers/helpers';
import { setActiveClosetAutomationPlatform } from '../slices/automationsSlice.ebay';
import { IClosetDetails } from 'src/pages/automations/MyClosetPage/utils/types';

function* getShopsListSaga() {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(poshmarkAutomationEndpoints.closet.GET_CLOSETS('ebay'))
    );

    const shopsList = response.data.data;
    const hasShop =
      shopsList.filter((shop: IClosetDetails) => shop.status !== ConnectionStatus.Removed).length >
      0;

    yield put(
      getShopsListSuccess(sortClosetListAccordingToStatus(shopsList as IClosetDetails[]).reverse())
    );

    if (hasShop) {
      const initialActiveShop = shopsList
        .filter((item: IClosetDetails) => item.status !== ConnectionStatus.Removed)
        .slice(-1)[0];
      yield put(setActiveCloset(initialActiveShop));
      yield put(setActiveClosetAutomationPlatform(initialActiveShop.country));
    }
  } catch (error) {
    yield put(getShopsListFail(error.message));
  }
}

function* removeClosetSaga(action: PayloadAction<IRemoveData>) {
  try {
    yield call(() =>
      axiosInstance.delete(
        poshmarkAutomationEndpoints.closet.REMOVE_CLOSET(action.payload.id, 'ebay')
      )
    );
    yield put(setActiveCloset({} as IClosetDetails));
    yield put(getShopsListRequest());
    yield put(removeClosetSuccess());
    toast.success(`Your shop '${action.payload.name}' successfully removed`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(removeClosetFail(error.message));
  }
}

function* addEbayShopSaga(action: PayloadAction<{ code: string }>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(poshmarkAutomationEndpoints.ebay.addEbayShop(), {
        code: action.payload.code,
      })
    );

    yield put(addEbayShopSuccess());
    yield put(getShopsListRequest()); // Refresh shops list after adding new shop

    toast.success('eBay shop successfully connected', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(addEbayShopFail(error.message));
    const errorMessage = error.error || 'Failed to connect eBay shop. Please try again.';
    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 5000,
    });
  }
}

function* getEbayAuthUrlSaga(action: PayloadAction<{ country: string }>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.myshops.ebay.GET_EBAY_API_AUTH({
          automation: true,
        }),
        {
          params: {
            country: action.payload.country,
          },
        }
      )
    );

    yield put(getEbayAuthUrlSuccess({ url: response.data.data }));
  } catch (error) {
    console.error('Failed to get eBay auth URL:', error);
    yield put(getEbayAuthUrlFail(error.message));
    toast.error('Failed to connect to eBay. Please try again.', {
      position: 'top-right',
      autoClose: 5000,
    });
  }
}

export function* myShopsModuleSaga() {
  yield takeLatest(getShopsListRequest.type, getShopsListSaga);
  yield takeLatest(removeClosetRequest.type, removeClosetSaga);
  yield takeLatest(addEbayShopRequest.type, addEbayShopSaga);
  yield takeLatest(getEbayAuthUrlRequest.type, getEbayAuthUrlSaga);
}
