import { Stack } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';
import { setIsConnectDialogOpen } from 'src/store/automations/slices/myClosetSlice';
import { setCurrentTab, SubscriptionStatus } from 'src/store/dashboard/slices/accountSlice';
import { setStripeReferrerUrl } from 'src/store/dashboard/slices/pricingSlice';
import LayoutAlert, { AlertTypes } from './LayoutAlert';
import useResponsive from 'src/hooks/useResponsive';
import { IRootState } from 'src/store';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';

interface IAlerts {
  alerts: {
    shouldRenderBanAlert?: boolean;
    shouldRenderExtensionAlert?: boolean;
    shouldRenderMobileDeviceAlert?: boolean;
    shouldRenderBrowserCompatibilityAlert?: boolean;
    shouldDisplayConnectionAlert?: boolean;
    shouldDisplayReconnectionAlert?: boolean;
    shouldDisplaySubscriptionAlert?: boolean;
  };
  isInAutomations: boolean;
}

const LayoutAlertContainer = ({ alerts, isInAutomations }: IAlerts) => {
  const url = window.location.href;
  const isMobile = useResponsive('down', 'sm');
  const { billingSubscriptionList } = useSelector((state: IRootState) => state.account);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { platform } = useAutomationPlatform();
  const location = useLocation();
  //--Alerts
  const {
    shouldDisplayConnectionAlert,
    shouldDisplayReconnectionAlert,
    shouldDisplaySubscriptionAlert,
    shouldRenderBanAlert,
    shouldRenderExtensionAlert,
    shouldRenderMobileDeviceAlert,
    shouldRenderBrowserCompatibilityAlert,
  } = alerts;

  const handleUserBlockStateAlert = () => {
    if (location.pathname.includes('automations')) {
      if (platform === AutomationPlatform.EBAY) {
        navigate(`${PATH_AUTOMATIONS.ebayAccount}#billing`);
      } else if (platform === AutomationPlatform.POSHMARK) {
        navigate(`${PATH_AUTOMATIONS.account}#billing`);
      }
    } else {
      dispatch(setCurrentTab('billing'));
      navigate(PATH_DASHBOARD.subscription.crosslist);
    }
  };

  const handleInstallExtensionAlertButton = () =>
    window.open(
      'https://chrome.google.com/webstore/detail/cross-listing-poshmark-to/eepbhjeldlodgnndnjofcnnoampciipg',
      '_blank',
      'noopener noreferrer'
    );

  const handleCromeBrowserAlertButton = () =>
    window.open('https://www.google.com/chrome/', '_blank', 'noopener noreferrer');

  const handleSubscriptionAlert = () => {
    const subscription = billingSubscriptionList.find((sub) =>
      sub.subscriptionName.toLowerCase().includes(platform.toLowerCase())
    );

    if (
      subscription?.status?.toLowerCase() === SubscriptionStatus.PAST_DUE &&
      subscription.invoicePaymentLink
    ) {
      window.open(subscription.invoicePaymentLink, '_blank', 'noopener noreferrer');
      return;
    }

    if (platform === AutomationPlatform.EBAY) {
      navigate(PATH_DASHBOARD.subscription.ebayAutomation);
    } else {
      navigate(PATH_DASHBOARD.subscription.poshmarkAutomation);
    }
    dispatch(setStripeReferrerUrl(url));
  };

  const handleClosetAlert = () => {
    if (platform === AutomationPlatform.EBAY) {
      navigate(PATH_AUTOMATIONS.ebayMyShops);
    } else {
      navigate(PATH_AUTOMATIONS.myCloset);
    }
  };

  const handleReconnectAlert = () => {
    if (platform === AutomationPlatform.EBAY) {
      navigate(PATH_AUTOMATIONS.ebayMyShops);
    } else {
      navigate(PATH_AUTOMATIONS.myCloset);
    }
  };

  const renderAutomationsAlerts = () => (
    <Stack
      spacing={'16px'}
      sx={{
        mt: '16px',
        mb: !isMobile ? '16px' : '24px',
        p: '0px !important',
      }}
    >
      {shouldDisplayConnectionAlert && (
        <LayoutAlert
          type={
            platform === AutomationPlatform.EBAY
              ? AlertTypes.EBAY_CONNECTION
              : AlertTypes.POSHMARK_CONNECTION
          }
          alertFunction={handleClosetAlert}
        />
      )}
      {shouldDisplayReconnectionAlert && (
        <LayoutAlert
          type={
            platform === AutomationPlatform.EBAY
              ? AlertTypes.EBAY_RECONNECTION
              : AlertTypes.POSHMARK_RECONNECTION
          }
          alertFunction={handleReconnectAlert}
        />
      )}
      {shouldRenderBanAlert && (
        <LayoutAlert type={AlertTypes.USER_BLOCK_STATE} alertFunction={handleUserBlockStateAlert} />
      )}
      {shouldDisplaySubscriptionAlert && (
        <LayoutAlert
          type={
            platform === AutomationPlatform.EBAY
              ? AlertTypes.SUBSCRIPTION_TO_EBAY
              : AlertTypes.SUBSCRIPTION_TO_POSHMARK
          }
          alertFunction={handleSubscriptionAlert}
        />
      )}
    </Stack>
  );

  const renderDashboardAlerts = () => (
    <Stack
      spacing={'16px'}
      sx={{
        mt: '16px',
        mb: '16px',
        p: '0px !important',
      }}
    >
      {shouldRenderBrowserCompatibilityAlert && (
        <LayoutAlert
          type={AlertTypes.BROWSER_COMPATIBILITY}
          alertFunction={handleCromeBrowserAlertButton}
        />
      )}
      {shouldRenderBanAlert && (
        <LayoutAlert type={AlertTypes.USER_BLOCK_STATE} alertFunction={handleUserBlockStateAlert} />
      )}
      {shouldRenderExtensionAlert && (
        <LayoutAlert
          type={AlertTypes.EXTENSION}
          alertFunction={handleInstallExtensionAlertButton}
        />
      )}
      {shouldRenderMobileDeviceAlert && <LayoutAlert type={AlertTypes.MOBILE_DEVICE} />}
    </Stack>
  );

  const renderContainers = () => {
    if (isInAutomations) {
      return renderAutomationsAlerts();
    } else {
      return renderDashboardAlerts();
    }
  };

  const isInPricingPages = location.pathname.includes('subscription/');

  return isInPricingPages ? null : renderContainers();
};

export default LayoutAlertContainer;
