import {
  Alert,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { AutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { IRootState } from 'src/store';

interface AnnouncementAlertProps {
  message: string;
  shortMessage: string;
  products: ProductTypeEnum[];
  expireDate: string;
}

export enum ProductTypeEnum {
  CROSS_LIST = 'crossList',
  POSHMARK_AUTOMATION = 'poshmarkAutomation',
  EBAY_AUTOMATION = 'ebayAutomation',
}

export default function AnnouncementAlert({
  message,
  shortMessage,
  products,
  expireDate,
}: AnnouncementAlertProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);
  // Get current product type based on path
  const getCurrentProduct = (): ProductTypeEnum => {
    const path = location.pathname.toLowerCase();
    if (path.includes(AutomationPlatform.EBAY)) return ProductTypeEnum.EBAY_AUTOMATION;
    if (path.includes(AutomationPlatform.POSHMARK)) return ProductTypeEnum.POSHMARK_AUTOMATION;
    return ProductTypeEnum.CROSS_LIST;
  };

  // Check if announcement should be shown for current product and not expired
  const shouldShowAnnouncement = (): boolean => {
    const currentProduct = getCurrentProduct();
    const isProductMatch = products.includes(currentProduct);

    // Check if announcement is expired based on user's timezone
    const userOffset = userAccountDetail.timeZone.offset || 0;
    const expirationMoment = moment.utc(expireDate);
    const userCurrentTime = moment().utcOffset(userOffset);

    const isExpired = userCurrentTime.isAfter(expirationMoment);

    return isProductMatch && !isExpired;
  };

  // If announcement is not for current product, don't render anything
  if (!shouldShowAnnouncement()) {
    return null;
  }

  // Handle dialog open/close
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Alert
        severity="info"
        action={
          <Button
            color="info"
            size="small"
            sx={{
              backgroundColor: 'white',
              color: 'black',
              whiteSpace: 'nowrap',
              '&:hover': { backgroundColor: '#F4F6F8' },
            }}
            variant="contained"
            onClick={handleOpenDialog}
          >
            Read More
          </Button>
        }
        sx={{
          width: '100%',
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap',
          backgroundColor: '#2065D1 !important',
          color: '#fff !important',
          fontWeight: 'bold !important',
          '& .MuiAlert-icon': {
            '& .MuiSvgIcon-root': {
              fill: 'white',
            },
          },
        }}
      >
        {shortMessage}
      </Alert>

      {/* Full message dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Announcement</DialogTitle>
        <DialogContent
          sx={{
            // Add word break to handle long text without horizontal scroll
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
            // Add max height and vertical scroll
            maxHeight: '50vh',
            overflowY: 'auto'
          }}
        >
          {message}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDialog}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
