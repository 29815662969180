import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAPIMarketplaces } from './myShopsAPITableSlice';
import {
  isEbay,
  isShopify,
} from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';
import { Marketplace } from '@beta.limited/primelister';

export interface IMyShopsTableRow {
  platformName: string;
  name: string;
  icon: string;
  shop: string;
  isLoading: boolean;
  isConnected: boolean;
  connectionLink: string;
  username: string;
}

export enum IPlatforms {
  MERCARI = 'mercari',
  POSHMARKUS = 'poshmark-us',
  POSHMARKCA = 'poshmark-ca',
  EBAYUS = 'ebay-us',
  EBAYAU = 'ebay-au',
  EBAYCA = 'ebay-ca',
  EBAYAT = 'ebay-at',
  EBAYBE = 'ebay-be',
  EBAYCH = 'ebay-ch',
  EBAYDE = 'ebay-de',
  EBAYES = 'ebay-es',
  EBAYFR = 'ebay-fr',
  EBAYGB = 'ebay-gb',
  EBAYHK = 'ebay-hk',
  EBAYIE = 'ebay-ie',
  EBAYIN = 'ebay-in',
  EBAYIT = 'ebay-it',
  EBAYMY = 'ebay-my',
  EBAYNL = 'ebay-nl',
  EBAYPH = 'ebay-ph',
  EBAYPL = 'ebay-pl',
  EBAYSG = 'ebay-sg',
  EBAYTW = 'ebay-tw',
  EBAYVN = 'ebay-vn',
  FACEBOOK = 'facebook',
  SHOPIFY = 'shopify',
  ETSY = 'etsy',
  GRAILED = 'grailed',
  DEPOP = 'depop',
}

export type IPlatformStateFinder =
  | IPlatforms.MERCARI
  | IPlatforms.POSHMARKUS
  | IPlatforms.POSHMARKCA
  | IPlatforms.FACEBOOK
  | IPlatforms.SHOPIFY
  | IPlatforms.ETSY
  | IPlatforms.GRAILED
  | IPlatforms.DEPOP
  | IPlatforms.EBAYUS
  | IPlatforms.EBAYAU
  | IPlatforms.EBAYCA
  | IPlatforms.EBAYAT
  | IPlatforms.EBAYBE
  | IPlatforms.EBAYCH
  | IPlatforms.EBAYDE
  | IPlatforms.EBAYES
  | IPlatforms.EBAYFR
  | IPlatforms.EBAYGB
  | IPlatforms.EBAYHK
  | IPlatforms.EBAYIE
  | IPlatforms.EBAYIN
  | IPlatforms.EBAYIT
  | IPlatforms.EBAYMY
  | IPlatforms.EBAYNL
  | IPlatforms.EBAYPH
  | IPlatforms.EBAYPL
  | IPlatforms.EBAYSG
  | IPlatforms.EBAYTW
  | IPlatforms.EBAYVN;

export const platformStateFinder: { [key: string]: string } = {
  [IPlatforms.MERCARI]: 'Mercari',
  [IPlatforms.POSHMARKUS]: 'PoshmarkUs',
  [IPlatforms.POSHMARKCA]: 'PoshmarkCa',
  [IPlatforms.FACEBOOK]: 'Facebook',
  [IPlatforms.SHOPIFY]: 'Shopify',
  [IPlatforms.ETSY]: 'Etsy',
  [IPlatforms.GRAILED]: 'Grailed',
  [IPlatforms.DEPOP]: 'Depop',
  [IPlatforms.EBAYUS]: 'EbayUs',
  [IPlatforms.EBAYAU]: 'EbayAu',
  [IPlatforms.EBAYCA]: 'EbayCa',
  [IPlatforms.EBAYAT]: 'EbayAt',
  [IPlatforms.EBAYBE]: 'EbayBe',
  [IPlatforms.EBAYCH]: 'EbayCh',
  [IPlatforms.EBAYDE]: 'EbayDe',
  [IPlatforms.EBAYES]: 'EbayEs',
  [IPlatforms.EBAYFR]: 'EbayFr',
  [IPlatforms.EBAYGB]: 'EbayGb',
  [IPlatforms.EBAYHK]: 'EbayHk',
  [IPlatforms.EBAYIE]: 'EbayIe',
  [IPlatforms.EBAYIN]: 'EbayIn',
  [IPlatforms.EBAYIT]: 'EbayIt',
  [IPlatforms.EBAYMY]: 'EbayMy',
  [IPlatforms.EBAYNL]: 'EbayNl',
  [IPlatforms.EBAYPH]: 'EbayPh',
  [IPlatforms.EBAYPL]: 'EbayPl',
  [IPlatforms.EBAYSG]: 'EbaySg',
  [IPlatforms.EBAYTW]: 'EbayTw',
  [IPlatforms.EBAYVN]: 'EbayVn',
};

export interface IMarketPlaceProps {
  isLoggedIn: boolean;
  isLoading: boolean;
  username: string;
  userId: string | number | null;
}

const defaultStateForMarketplaces: IMarketPlaceProps = {
  isLoggedIn: false,
  isLoading: false,
  username: '',
  userId: null,
};

export interface IMyShopsState {
  selectedActionRow: IMyShopsTableRow;
  isBrowserTableActionsMenuOpen: HTMLElement | null;
  isAPITableActionsMenuOpen: HTMLElement | null;
  isAllPlatformsLoading: boolean;
  isNotLoggedInDialogOpen: boolean;
  //--
  [IPlatforms.DEPOP]: IMarketPlaceProps;
  [IPlatforms.ETSY]: IMarketPlaceProps;
  [IPlatforms.FACEBOOK]: IMarketPlaceProps;
  [IPlatforms.GRAILED]: IMarketPlaceProps;
  [IPlatforms.MERCARI]: IMarketPlaceProps;
  [IPlatforms.POSHMARKCA]: IMarketPlaceProps;
  [IPlatforms.POSHMARKUS]: IMarketPlaceProps;
  [IPlatforms.EBAYCA]: IMarketPlaceProps;
  [IPlatforms.EBAYUS]: IMarketPlaceProps;
  [IPlatforms.EBAYAU]: IMarketPlaceProps;
  [IPlatforms.EBAYAT]: IMarketPlaceProps;
  [IPlatforms.EBAYBE]: IMarketPlaceProps;
  [IPlatforms.EBAYCH]: IMarketPlaceProps;
  [IPlatforms.EBAYDE]: IMarketPlaceProps;
  [IPlatforms.EBAYES]: IMarketPlaceProps;
  [IPlatforms.EBAYFR]: IMarketPlaceProps;
  [IPlatforms.EBAYGB]: IMarketPlaceProps;
  [IPlatforms.EBAYHK]: IMarketPlaceProps;
  [IPlatforms.EBAYIE]: IMarketPlaceProps;
  [IPlatforms.EBAYIN]: IMarketPlaceProps;
  [IPlatforms.EBAYIT]: IMarketPlaceProps;
  [IPlatforms.EBAYMY]: IMarketPlaceProps;
  [IPlatforms.EBAYNL]: IMarketPlaceProps;
  [IPlatforms.EBAYPH]: IMarketPlaceProps;
  [IPlatforms.EBAYPL]: IMarketPlaceProps;
  [IPlatforms.EBAYSG]: IMarketPlaceProps;
  [IPlatforms.EBAYTW]: IMarketPlaceProps;
  [IPlatforms.EBAYVN]: IMarketPlaceProps;
  [IPlatforms.SHOPIFY]: IMarketPlaceProps;
  isOfficialAPIShopify: boolean;
  isOfficialAPIEbay: boolean;
  //--
  [key: string]: boolean | IMyShopsTableRow | HTMLElement | null | IMarketPlaceProps;
}

const initialState = {
  selectedActionRow: {},
  isBrowserTableActionsMenuOpen: null,
  isAPITableActionsMenuOpen: null,
  isAllPlatformsLoading: false,
  isNotLoggedInDialogOpen: false,
  //--
  [IPlatforms.DEPOP]: defaultStateForMarketplaces,
  [IPlatforms.ETSY]: defaultStateForMarketplaces,
  [IPlatforms.FACEBOOK]: defaultStateForMarketplaces,
  [IPlatforms.GRAILED]: defaultStateForMarketplaces,
  [IPlatforms.MERCARI]: defaultStateForMarketplaces,
  [IPlatforms.EBAYUS]: defaultStateForMarketplaces,
  [IPlatforms.EBAYCA]: defaultStateForMarketplaces,
  [IPlatforms.EBAYAU]: defaultStateForMarketplaces,
  [IPlatforms.EBAYAT]: defaultStateForMarketplaces,
  [IPlatforms.EBAYBE]: defaultStateForMarketplaces,
  [IPlatforms.EBAYCH]: defaultStateForMarketplaces,
  [IPlatforms.EBAYDE]: defaultStateForMarketplaces,
  [IPlatforms.EBAYES]: defaultStateForMarketplaces,
  [IPlatforms.EBAYFR]: defaultStateForMarketplaces,
  [IPlatforms.EBAYGB]: defaultStateForMarketplaces,
  [IPlatforms.EBAYHK]: defaultStateForMarketplaces,
  [IPlatforms.EBAYIE]: defaultStateForMarketplaces,
  [IPlatforms.EBAYIN]: defaultStateForMarketplaces,
  [IPlatforms.EBAYIT]: defaultStateForMarketplaces,
  [IPlatforms.EBAYMY]: defaultStateForMarketplaces,
  [IPlatforms.EBAYNL]: defaultStateForMarketplaces,
  [IPlatforms.EBAYPH]: defaultStateForMarketplaces,
  [IPlatforms.EBAYPL]: defaultStateForMarketplaces,
  [IPlatforms.EBAYSG]: defaultStateForMarketplaces,
  [IPlatforms.EBAYTW]: defaultStateForMarketplaces,
  [IPlatforms.EBAYVN]: defaultStateForMarketplaces,
  [IPlatforms.POSHMARKCA]: defaultStateForMarketplaces,
  [IPlatforms.POSHMARKUS]: defaultStateForMarketplaces,
  [IPlatforms.SHOPIFY]: defaultStateForMarketplaces,
  isOfficialAPIShopify: false,
  isOfficialAPIEbay: false,
};

const myShopsSlice = createSlice({
  name: 'myShops',
  initialState,
  reducers: {
    setSelectedActionRow: (state, action: PayloadAction<IMyShopsTableRow>) => {
      state.selectedActionRow = action.payload;
      if (action.payload.platformName) {
        state.isOfficialAPIShopify = isShopify(action.payload.platformName);
        state.isOfficialAPIEbay = isEbay(action.payload.platformName);
      }
    },
    setIsBrowserTableActionsMenuOpen: (state, action) => {
      state.isBrowserTableActionsMenuOpen = action.payload;
    },
    setIsAPITableActionsMenuOpen: (state, action) => {
      state.isAPITableActionsMenuOpen = action.payload;
    },
    setIsNotLoggedInDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isNotLoggedInDialogOpen = action.payload;
    },
    setIsPlatformLoading: (
      state,
      action: PayloadAction<{ isLoading: boolean; platform: IPlatformStateFinder }>
    ) => {
      const platformKey = action.payload.platform;
      state[platformKey].isLoading = action.payload.isLoading;
    },

    setIsPlatformLoggedIn: (
      state,
      action: PayloadAction<{ isLoggedIn: boolean; platform: IPlatformStateFinder }>
    ) => {
      const platformKey = action.payload.platform;
      state[platformKey].isLoggedIn = action.payload.isLoggedIn;
    },
    setPlatformData: (
      state,
      action: PayloadAction<{
        username: string;
        userId: string | number;
        platform: IPlatformStateFinder;
      }>
    ) => {
      const platformKey = action.payload.platform;
      state[platformKey].username = action.payload.username;
      state[platformKey].userId = action.payload.userId;
    },
    setAllPlatformsLoading: (state, action) => {
      state.isAllPlatformsLoading = action.payload;
    },
    setAllPlatformsInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.isAllPlatformsLoading = action.payload;

      // Update loading state for all platforms dynamically
      Object.values(IPlatforms).forEach((platform) => {
        state[platform].isLoading = action.payload;
      });
    },
    resetMyShopsState: (state) => initialState,
  },
});

export const {
  resetMyShopsState,
  setSelectedActionRow,
  setIsBrowserTableActionsMenuOpen,
  setIsAPITableActionsMenuOpen,
  setIsNotLoggedInDialogOpen,
  //--
  setAllPlatformsInitialLoading,
  setIsPlatformLoading,
  setIsPlatformLoggedIn,
  setPlatformData,
} = myShopsSlice.actions;

export default myShopsSlice.reducer;
