// routes
import { PATH_AUTH } from '../routes/paths';
// utils
import axios from '../utils/axios';
import cookie from 'react-cookies';
import { CompareVersionEnum, DeviceTypeEnum } from './types';

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 6 months
  // const timeLeft = currentTime + (6 * 30 * 24 * 60 * 60 * 1000) - currentTime; // ~10s
  const timeLeft = exp * (6 * 30 * 24 * 60 * 60 * 1000) - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = window.setTimeout(() => {
    alert('Token expired');
    cookie.remove('accessToken');
    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const getExpiresDateForCookies = () => {
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 1);
  return expires;
};

export const setSession = (
  accessToken: string | null,
  refreshToken: string | null,
  email: string | null,
  fullName: string | null,
  proStatus: string | null,
  blockState: number | null,
  userId: string | null,
  referrerCode: string | null,
  createdAt: number | null
) => {
  if (accessToken && refreshToken) {
    const userData = {
      accessToken,
      refreshToken,
      email: email || '',
      fullName: fullName || '',
      proStatus: proStatus || '',
      blockState: blockState || '',
      userId: userId || '',
      referrerCode: referrerCode || '',
      createdAt: createdAt || 0,
    }; // set expires to 1 year from now
    cookie.save('userData', userData, { path: '/', expires: getExpiresDateForCookies() });

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // tokenExpired(exp);
  } else {
    cookie.remove('userData');
    delete axios.defaults.headers.common.Authorization;
  }
};

export function parseUserAgent(ua: string) {
  if (ua === 'PrimeListerMobileApp') {
    return {
      app_name: 'PrimeListerMobileApp',
      version: '1.0.0',
      platform: 'iOS 14.5', // Default device
      device: 'iPhone 12 Pro Max', // Default device
    };
  }
  var match = ua.match(/([^\s]+)\/([^\s]+)\s\(([^;]+);\s([^)]+)\)/);
  if (match) {
    return {
      app_name: match[1],
      version: match[2],
      platform: match[3],
      device: match[4],
    };
  } else {
    return null;
  }
}

export function getDevicePlatform(platform: string) {
  return platform.toLowerCase().includes('ios') ? DeviceTypeEnum.IOS : DeviceTypeEnum.ANDROID;
}

/**
 * This function is used to compare two software version numbers.
 * It uses CompareVersionEnum Enumerators to define whether version1 is LESSER, GREATER or EQUAL to version2.
 */
export function compareVersions(version1: string, version2: string) {
  // if the versions are identical, return ENUM that indicates equality
  if (version1 === version2) return CompareVersionEnum.EQUAL;

  // split the versions by '.', converting each segment to a number for comparison
  let v1 = version1.split('.').map(Number);
  let v2 = version2.split('.').map(Number);

  let comparisonDepth = Math.min(v1.length, v2.length);

  // comparing each segment in both version numbers (from left to right)
  for (let i = 0; i < comparisonDepth; ++i) {
    if (v1[i] > v2[i]) return CompareVersionEnum.GREATER; // if v1 segment is greater, v1 is the higher version
    if (v1[i] < v2[i]) return CompareVersionEnum.LESSER; // if v2 segment is greater, v2 is the higher version
  }

  // if all comparable segments were equal, determine the higher version based
  // on the longer version number (i.e., it has more segments)
  return v1.length === v2.length
    ? CompareVersionEnum.EQUAL
    : v1.length < v2.length
    ? CompareVersionEnum.LESSER
    : CompareVersionEnum.GREATER;
}
