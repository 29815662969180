import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import {
  setIsDowngradeConfirmationDialogOpen,
  updateSubscriptionRequest,
} from 'src/store/dashboard/slices/pricingSlice';
import { IRootState } from 'src/store';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';

const DowngradeConfirmationDialog = () => {
  const dispatch = useDispatch();
  const { platform } = useAutomationPlatform();

  const {
    isDowngradeConfirmationDialogOpen,
    sliderValue,
    stripeReferrerUrl,
    stripePlanIdForUpdate,
  } = useSelector((state: IRootState) => state.pricing);

  const { poshmarkShopCapacity, ebayShopCapacity, billingSubscriptionList } = useSelector(
    (state: IRootState) => state.account
  );

  const shopWord = platform === AutomationPlatform.EBAY ? 'shop' : 'closet';
  const currentCapacity =
    platform === AutomationPlatform.EBAY ? ebayShopCapacity : poshmarkShopCapacity;
  const subscriptionIndex = platform === AutomationPlatform.EBAY ? 2 : 1;

  const handleClose = () =>
    dispatch(setIsDowngradeConfirmationDialogOpen({ openDialog: false, stripePlanId: '' }));

  const confirmationMessage = [
    `Your current subscription allows up to ${currentCapacity} ${shopWord} connections. By downgrading your subscription, you will switch to a plan that allows up to ${sliderValue} ${shopWord} connections only.`,
    `Please note that these changes will take effect immediately.`,
    `Are you sure you want to proceed with downgrading your subscription?`,
  ];

  const handleDowngrade = () => {
    const stripeReferrerUrlControlled = stripeReferrerUrl
      ? stripeReferrerUrl
      : window.location.host + '/automations/user#gbilling';

    dispatch(
      updateSubscriptionRequest({
        returnUrl: stripeReferrerUrlControlled,
        flowType: 'subscription_update_confirm',
        subscriptionId: billingSubscriptionList[subscriptionIndex].subscriptionId,
        subscriptionItemId: billingSubscriptionList[subscriptionIndex].subscriptionItemId,
        planId: stripePlanIdForUpdate,
        shopCapacity: sliderValue,
      })
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isDowngradeConfirmationDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 3 }}>Downgrade Your Subscription: Confirmation Required</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        {confirmationMessage.map((message, index) => (
          <Typography
            sx={{
              ...(index < 2 && { pb: 3 }),
              fontSize: '16px',
              fontWeight: '400',
              color: '#637381',
            }}
            key={index}
          >
            {message}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleDowngrade}>
          Downgrade My Subscription
        </Button>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DowngradeConfirmationDialog;
