import {
  updateAutomationSettingsRequest as poshmarkUpdateAutomationSettingsRequest,
  setIsConnectionErrorDialogOpen as poshmarkSetIsConnectionErrorDialogOpen,
  setIsDisableConfirmationDialogOpen as poshmarkSetIsDisableConfirmationDialogOpen,
  createAutomationWorkerRequest as poshmarkCreateAutomationWorkerRequest,
  setIsAutomationOptionDrawerOpen as poshmarkSetIsAutomationOptionDrawerOpen,
  setSelectedAutomationOption as poshmarkSetSelectedAutomationOption,
  setAutomationsSettings as poshmarkSetAutomationsSettings,
  getFilterCategoriesRequest as poshmarkGetFilterCategoriesRequest,
  setHourlyRelistLimit as poshmarkSetHourlyRelistLimit,
  setRelistLimitError as poshmarkSetRelistLimitError,
  setIsRelistHourDialogOpen as poshmarkSetIsRelistHourDialogOpen,
  setRelistHourDialogError as poshmarkSetRelistHourDialogError,
  setInitialLoading as poshmarkSetInitialLoading,
} from 'src/store/automations/slices/automationsSlice';

import {
  setInitialLoading as poshmarkSetStatisticsInitialLoading,
  getAutomationCountsRequest as poshmarkGetAutomationCountsRequest,
  setDateOptionsForStatistics as poshmarkSetDateOptionsForStatistics,
} from 'src/store/automations/slices/statisticsSlice';

import {
  getActivityListRequest as poshmarkGetActivityListRequest,
  setSelectedActivity as poshmarkSetSelectedActivity,
  setActivityList as poshmarkSetActivityList,
  setSelectedOngoingTask as poshmarkSetSelectedOngoingTask,
  setInitialLoading as poshmarkActivitySetInitialLoading,
} from 'src/store/automations/slices/activitySlice';

import {
  updateAutomationSettingsRequest as ebayUpdateAutomationSettingsRequest,
  setIsConnectionErrorDialogOpen as ebaySetIsConnectionErrorDialogOpen,
  setIsDisableConfirmationDialogOpen as ebaySetIsDisableConfirmationDialogOpen,
  createAutomationWorkerRequest as ebayCreateAutomationWorkerRequest,
  setIsAutomationOptionDrawerOpen as ebaySetIsAutomationOptionDrawerOpen,
  setSelectedAutomationOption as ebaySetSelectedAutomationOption,
  setAutomationsSettings as ebaySetAutomationsSettings,
  setHourlyRelistLimit as ebaySetHourlyRelistLimit,
  setRelistLimitError as ebaySetRelistLimitError,
  setIsRelistHourDialogOpen as ebaySetIsRelistHourDialogOpen,
  setRelistHourDialogError as ebaySetRelistHourDialogError,
  setInitialLoading as ebaySetInitialLoading,
} from 'src/store/automations/ebay/slices/automationsSlice.ebay';

import {
  setInitialLoading as ebaySetStatisticsInitialLoading,
  getAutomationCountsRequest as ebayGetAutomationCountsRequest,
  setDateOptionsForStatistics as ebaySetDateOptionsForStatistics,
} from 'src/store/automations/ebay/slices/statisticsSlice.ebay';

import {
  getActivityListRequest as ebayGetActivityListRequest,
  setSelectedActivity as ebaySetSelectedActivity,
  setActivityList as ebaySetActivityList,
  setSelectedOngoingTask as ebaySetSelectedOngoingTask,
  setInitialLoading as ebayActivitySetInitialLoading,
} from 'src/store/automations/ebay/slices/activitySlice.ebay';

import {
  setShouldOpenExpiredSubscriptionDialog as poshmarkSetShouldOpenExpiredSubscriptionDialog,
  setIsRemoveClosetDialogOpen as poshmarkSetIsRemoveClosetDialogOpen,
  setIsUpgradeSubscriptionDialogOpen as poshmarkSetIsUpgradeSubscriptionDialogOpen,
  setIsConnectDialogOpen as poshmarkSetIsConnectDialogOpen,
} from 'src/store/automations/slices/myClosetSlice';
import {
  setShouldOpenExpiredSubscriptionDialog as ebaySetShouldOpenExpiredSubscriptionDialog,
  setIsRemoveClosetDialogOpen as ebaySetIsRemoveClosetDialogOpen,
  setIsUpgradeSubscriptionDialogOpen as ebaySetIsUpgradeSubscriptionDialogOpen,
  setIsConnectDialogOpen as ebaySetIsConnectDialogOpen,
} from 'src/store/automations/ebay/slices/myShopsSlice.ebay';

import { AutomationPlatform } from 'src/hooks/useAutomationPlatform';

export const getPlatformActions = (platform: AutomationPlatform) => ({
  updateAutomationSettingsRequest:
    platform === AutomationPlatform.EBAY
      ? ebayUpdateAutomationSettingsRequest
      : poshmarkUpdateAutomationSettingsRequest,
  setIsConnectionErrorDialogOpen:
    platform === AutomationPlatform.EBAY
      ? ebaySetIsConnectionErrorDialogOpen
      : poshmarkSetIsConnectionErrorDialogOpen,
  setIsDisableConfirmationDialogOpen:
    platform === AutomationPlatform.EBAY
      ? ebaySetIsDisableConfirmationDialogOpen
      : poshmarkSetIsDisableConfirmationDialogOpen,
  createAutomationWorkerRequest:
    platform === AutomationPlatform.EBAY
      ? ebayCreateAutomationWorkerRequest
      : poshmarkCreateAutomationWorkerRequest,
  setIsAutomationOptionDrawerOpen:
    platform === AutomationPlatform.EBAY
      ? ebaySetIsAutomationOptionDrawerOpen
      : poshmarkSetIsAutomationOptionDrawerOpen,
  setSelectedAutomationOption:
    platform === AutomationPlatform.EBAY
      ? ebaySetSelectedAutomationOption
      : poshmarkSetSelectedAutomationOption,
  setAutomationsSettings:
    platform === AutomationPlatform.EBAY
      ? ebaySetAutomationsSettings
      : poshmarkSetAutomationsSettings,
  setShouldOpenExpiredSubscriptionDialog:
    platform === AutomationPlatform.EBAY
      ? ebaySetShouldOpenExpiredSubscriptionDialog
      : poshmarkSetShouldOpenExpiredSubscriptionDialog,
  setIsRemoveClosetDialogOpen:
    platform === AutomationPlatform.EBAY
      ? ebaySetIsRemoveClosetDialogOpen
      : poshmarkSetIsRemoveClosetDialogOpen,
  setHourlyRelistLimit:
    platform === AutomationPlatform.EBAY ? ebaySetHourlyRelistLimit : poshmarkSetHourlyRelistLimit,
  setRelistLimitError:
    platform === AutomationPlatform.EBAY ? ebaySetRelistLimitError : poshmarkSetRelistLimitError,
  setIsRelistHourDialogOpen:
    platform === AutomationPlatform.EBAY
      ? ebaySetIsRelistHourDialogOpen
      : poshmarkSetIsRelistHourDialogOpen,
  setRelistHourDialogError:
    platform === AutomationPlatform.EBAY
      ? ebaySetRelistHourDialogError
      : poshmarkSetRelistHourDialogError,
  getActivityListRequest:
    platform === AutomationPlatform.EBAY
      ? ebayGetActivityListRequest
      : poshmarkGetActivityListRequest,
  setInitialLoading:
    platform === AutomationPlatform.EBAY ? ebaySetInitialLoading : poshmarkSetInitialLoading,
  activitySetInitialLoading:
    platform === AutomationPlatform.EBAY
      ? ebayActivitySetInitialLoading
      : poshmarkActivitySetInitialLoading,
  setSelectedActivity:
    platform === AutomationPlatform.EBAY ? ebaySetSelectedActivity : poshmarkSetSelectedActivity,
  setStatisticsInitialLoading:
    platform === AutomationPlatform.EBAY
      ? ebaySetStatisticsInitialLoading
      : poshmarkSetStatisticsInitialLoading,
  getAutomationCountsRequest:
    platform === AutomationPlatform.EBAY
      ? ebayGetAutomationCountsRequest
      : poshmarkGetAutomationCountsRequest,
  setDateOptionsForStatistics:
    platform === AutomationPlatform.EBAY
      ? ebaySetDateOptionsForStatistics
      : poshmarkSetDateOptionsForStatistics,
  setActivityList:
    platform === AutomationPlatform.EBAY ? ebaySetActivityList : poshmarkSetActivityList,
  setSelectedOngoingTask:
    platform === AutomationPlatform.EBAY
      ? ebaySetSelectedOngoingTask
      : poshmarkSetSelectedOngoingTask,
  setIsUpgradeSubscriptionDialogOpen:
    platform === AutomationPlatform.EBAY
      ? ebaySetIsUpgradeSubscriptionDialogOpen
      : poshmarkSetIsUpgradeSubscriptionDialogOpen,
  setIsConnectDialogOpen:
    platform === AutomationPlatform.EBAY
      ? ebaySetIsConnectDialogOpen
      : poshmarkSetIsConnectDialogOpen,
});
