import { Marketplace, EbayCountry } from '@beta.limited/primelister';
import { IClosetDetails } from 'src/pages/automations/MyClosetPage/utils/types';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import { PATH_AUTOMATIONS } from 'src/routes/paths';

export const sortClosetListAccordingToStatus = (closetList: IClosetDetails[]): IClosetDetails[] => {
  const sortedClosetList = function sortObjects() {
    return closetList.sort((a: IClosetDetails, b: IClosetDetails) => {
      if (a.status !== ConnectionStatus.Removed && b.status === ConnectionStatus.Removed) {
        return 1;
      } else if (a.status === ConnectionStatus.Removed && b.status !== ConnectionStatus.Removed) {
        return -1;
      } else {
        return 0;
      }
    });
  };
  return sortedClosetList();
};

export const getClosetCountry = (country: string): Marketplace => {
  const isEbayPath = window.location.pathname.includes(PATH_AUTOMATIONS.ebayRoot);

  if (isEbayPath) {
    const ebayCountryMapping: Partial<Record<EbayCountry, Marketplace>> = {
      [EbayCountry.us]: Marketplace.EBAY_US,
      [EbayCountry.ca]: Marketplace.EBAY_CA,
      [EbayCountry.au]: Marketplace.EBAY_AU,
      [EbayCountry.at]: Marketplace.EBAY_AT,
      [EbayCountry.be]: Marketplace.EBAY_BE,
      [EbayCountry.ch]: Marketplace.EBAY_CH,
      [EbayCountry.de]: Marketplace.EBAY_DE,
      [EbayCountry.es]: Marketplace.EBAY_ES,
      [EbayCountry.fr]: Marketplace.EBAY_FR,
      [EbayCountry.gb]: Marketplace.EBAY_GB,
      [EbayCountry.hk]: Marketplace.EBAY_HK,
      [EbayCountry.ie]: Marketplace.EBAY_IE,
      [EbayCountry.in]: Marketplace.EBAY_IN,
      [EbayCountry.it]: Marketplace.EBAY_IT,
      [EbayCountry.my]: Marketplace.EBAY_MY,
      [EbayCountry.nl]: Marketplace.EBAY_NL,
      [EbayCountry.ph]: Marketplace.EBAY_PH,
      [EbayCountry.pl]: Marketplace.EBAY_PL,
      [EbayCountry.sg]: Marketplace.EBAY_SG,
      [EbayCountry.tw]: Marketplace.EBAY_TW,
      [EbayCountry.vn]: Marketplace.EBAY_VN,
    };
    return ebayCountryMapping[country.toLowerCase() as EbayCountry] ?? Marketplace.EBAY_US;
  }

  const poshmarkCountryMapping: Record<string, string> = {
    us: Marketplace.POSHMARK_US,
    ca: Marketplace.POSHMARK_CA,
  };

  return poshmarkCountryMapping[country.toLowerCase()] as Marketplace;
};
