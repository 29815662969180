import { MenuItem, Button, useMediaQuery, Avatar } from '@mui/material';
import QueueIcon from '@mui/icons-material/Queue';

import { useDispatch, useSelector } from 'react-redux';
import {
  setIsManageSubscriptionsDialogOpen,
  setManageSubscriptionDialogData,
  setOpenAPIConnectionRequiredDialog,
  setOpenDownloadExtensionDialog,
} from 'src/store/dashboard/slices/inventorySlice';
import {
  setImportDialogHeader,
  setImportPlatform,
  setHasMoreImportableListings,
  setHideImportedItems,
  setOpenImportDialog,
} from 'src/store/dashboard/slices/importTableSlice';

import MenuPopover from 'src/components/menu-popover';
import { useEffect, useState } from 'react';

import { IRootState } from 'src/store';
import { IPlatforms, setIsNotLoggedInDialogOpen } from 'src/store/dashboard/slices/myShopsSlice';
import usePlatformLoginStatusCheck, { ActionType } from 'src/hooks/usePlatformLoginStatusCheck';
import {
  IAPIMarketplaces,
  officialApiPlatforms,
} from 'src/store/dashboard/slices/myShopsAPITableSlice';
import useResponsive from 'src/hooks/useResponsive';
import {
  getConnectedEbayShop,
  getShopNameAndId,
  getShopperId,
  isEbay,
} from '../mainComponents/ImportTable/importDataGrid/helpers';
import { getPlatformIcon } from '../../MyShops/component/MyShopsBrowserTable/BrowserTable';
import useCheckPermission, { PermissionCheckActionEnum } from 'src/hooks/useCheckPermission';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import { BackgroundActionsEnum } from 'src/utils/ExtensionMessageChannel';
import { Marketplace } from '@beta.limited/primelister';
// import { useFlags } from 'flagsmith/react';

const dropdownItems: { icon: string; name: IPlatforms; displayName: string; value: number }[] = [
  {
    icon: getPlatformIcon('poshmark'),
    name: IPlatforms.POSHMARKUS,
    displayName: 'Poshmark (US)',
    value: 0,
  },
  {
    icon: getPlatformIcon('poshmark'),
    name: IPlatforms.POSHMARKCA,
    displayName: 'Poshmark (CA)',
    value: 2,
  },
  {
    icon: getPlatformIcon('mercari'),
    name: IPlatforms.MERCARI,
    displayName: 'Mercari',
    value: 10,
  },
  {
    icon: getPlatformIcon('depop'),
    name: IPlatforms.DEPOP,
    displayName: 'Depop',
    value: 6,
  },
  {
    icon: getPlatformIcon('ebay'),
    name: IPlatforms.EBAYUS,
    displayName: 'eBay',
    value: 3,
  },
  // {
  //   name: IPlatforms.EBAYCA,
  //   displayName: 'eBay (CA)',
  //   value: 4,
  // },
  // {
  //   name: IPlatforms.EBAYAU,
  //   displayName: 'eBay (AU)',
  //   value: 5,
  // },
  {
    icon: getPlatformIcon('etsy'),
    name: IPlatforms.ETSY,
    displayName: 'Etsy',
    value: 7,
  },
  {
    icon: getPlatformIcon('shopify'),
    name: IPlatforms.SHOPIFY,
    displayName: 'Shopify',
    value: 12,
  },
  {
    icon: getPlatformIcon('facebook'),
    name: IPlatforms.FACEBOOK,
    displayName: 'Facebook',
    value: 8,
  },
  {
    icon: getPlatformIcon('grailed'),
    name: IPlatforms.GRAILED,
    displayName: 'Grailed',
    value: 9,
  },
];

const ImportButtonDropDown = () => {
  const [openDropdown, setOpenDropdown] = useState<HTMLElement | null>(null);
  const matches = useMediaQuery('(min-width:300px)');
  const dispatch = useDispatch();
  const { importSearchText, isOfficialAPIEbay, isOfficialAPIShopify, importPlatform } = useSelector(
    (state: IRootState) => state.importTable
  );
  const myShops = useSelector((state: IRootState) => state.myShops);
  const myShopsAPITable = useSelector((state: IRootState) => state.myShopsAPITable);
  const { getLoginStatusOfMarketplace } = usePlatformLoginStatusCheck();
  const isMobile = useResponsive('down', 900);
  const { checkPermission } = useCheckPermission();

  const updateCookies = async (marketplace: string) => {
    // If the marketplace is Mercari or Depop, use the userId instead of the username
    const isUserIdMarketplace = [Marketplace.MERCARI, Marketplace.DEPOP].includes(
      marketplace as Marketplace
    );

    const shopId = getShopperId(marketplace as Marketplace, myShops);

    await window.extension.sendCommand(BackgroundActionsEnum.UPDATE_COOKIES, {
      marketplace,
      shopperId: shopId,
    });
  };

  // const flags = useFlags(['poshmarkautomation', 'oauth']);
  // const isOAuthEnabledMyShops = flags.oauth.enabled;
  const isOAuthEnabledMyShops = false;

  const handleImportOption = async (platformDisplayName: string, platformName: string) => {
    setOpenDropdown(null);

    /* This is for updating the cookies for the platform by sending command to extension,
     we don't need to wait this to be done to continue */
    updateCookies(platformName);

    try {
      await checkPermission(platformName, PermissionCheckActionEnum.IMPORT);

      const openExternalImport = () => {
        const isShopifyRedirection = platformName === 'shopify';
        const url = isShopifyRedirection
          ? `https://admin.shopify.com/store/${myShops.shopify.username}/products`
          : 'https://www.facebook.com/marketplace/you/selling';
        window.open(url, '_blank', 'noopener noreferrer');
      };

      // If we have the ebay shop data, we can assume that user has eBay shop
      const ebayShopData = getConnectedEbayShop(myShopsAPITable);

      const handleInternalImport = () => {
        dispatch(setHasMoreImportableListings(false));
        dispatch(setHideImportedItems(false));
        dispatch(setImportPlatform(platformName));
        dispatch(setImportDialogHeader(platformDisplayName));
        dispatch(setOpenImportDialog(true));
      };

      const isOfficialApiPlatform = officialApiPlatforms.includes(
        platformName as unknown as IAPIMarketplaces
      );

      const isLoggedInToMarketplace = getLoginStatusOfMarketplace(
        ActionType.IMPORT,
        platformName as IPlatforms
      );

      if ((isOAuthEnabledMyShops && isOfficialApiPlatform) || window.extension.isConnected) {
        if (isLoggedInToMarketplace) {
          switch (platformDisplayName) {
            case 'Facebook':
              openExternalImport();
              break;
            case 'eBay':
              // If it's eBay and the user is not connected, open the API connection required dialog
              if (ebayShopData && ebayShopData.connectionStatus !== ConnectionStatus.Connected) {
                dispatch(setOpenAPIConnectionRequiredDialog(true));
              } else {
                handleInternalImport();
              }
              break;
            default:
              handleInternalImport();
              break;
          }
        } else {
          if (isEbay(platformName) && !ebayShopData) {
            dispatch(setOpenAPIConnectionRequiredDialog(true));
          } else {
            dispatch(setIsNotLoggedInDialogOpen(true));
          }
        }
      } else {
        if (isEbay(platformName) && !ebayShopData) {
          dispatch(setOpenAPIConnectionRequiredDialog(true));
        } else {
          dispatch(setOpenDownloadExtensionDialog(true));
        }
      }
    } catch (error) {
      dispatch(setManageSubscriptionDialogData(error));
      dispatch(setIsManageSubscriptionsDialogOpen(true));
    }
  };

  const handlePopoverClose = () => setOpenDropdown(null);

  const handleDropdown = (e: React.MouseEvent<HTMLElement>) => setOpenDropdown(e.currentTarget);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleDropdown}
        sx={{
          fontSize: matches ? '14px' : '12px',
          width: !isMobile ? '157.2px' : '100%',
          minWidth: !isMobile ? '157.2px' : '100%',
          padding: '5.75px 15.643px 6.25px 16px',
        }}
      >
        <QueueIcon sx={{ mr: 1, fontSize: '1rem' }} />
        Import Listings
      </Button>
      <MenuPopover
        open={openDropdown}
        onClose={handlePopoverClose}
        sx={{ minWidth: 140, mt: 1.5 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        arrow="top-center"
      >
        {dropdownItems.map((item) => (
          <MenuItem
            onClick={() => handleImportOption(item.displayName, item.name)}
            key={item.value}
            sx={{ fontWeight: '400 !important' }}
          >
            <Avatar src={item.icon} sx={{ width: 24, height: 24, mr: 1 }} />
            {item.displayName}
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
};

export default ImportButtonDropDown;
