import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';
import { EbaySiteMapper, EbayCountry, Marketplace } from '@beta.limited/primelister';

enum LoginCheckType {
  EXTENSION = 'extension',
  API = 'api',
  BOTH = 'both',
}

export enum ActionType {
  RELIST = 'relist',
  CROSSLIST = 'crosslist',
  DUPLICATE = 'duplicate',
  IMPORT = 'import',
  DELETE = 'delete',
  DELIST = 'delist',
}

interface PlatformActionConfig {
  [action: string]: LoginCheckType;
}

type PlatformConfigMap = {
  [key in IPlatforms]: PlatformActionConfig;
};

const generateEbayPlatformConfig = (platform: IPlatforms): PlatformActionConfig => ({
  [ActionType.CROSSLIST]: LoginCheckType.BOTH,
  [ActionType.IMPORT]: LoginCheckType.API,
  [ActionType.DELETE]: LoginCheckType.EXTENSION,
  [ActionType.RELIST]: LoginCheckType.API,
});

// Create base platform config for non-eBay platforms
const basePlatformConfig: Partial<PlatformConfigMap> = {
  [IPlatforms.MERCARI]: {
    [ActionType.RELIST]: LoginCheckType.EXTENSION,
    [ActionType.CROSSLIST]: LoginCheckType.EXTENSION,
    [ActionType.DUPLICATE]: LoginCheckType.EXTENSION,
    [ActionType.IMPORT]: LoginCheckType.EXTENSION,
    [ActionType.DELETE]: LoginCheckType.EXTENSION,
    [ActionType.DELIST]: LoginCheckType.EXTENSION,
  },
  [IPlatforms.FACEBOOK]: {
    [ActionType.CROSSLIST]: LoginCheckType.EXTENSION,
    [ActionType.IMPORT]: LoginCheckType.EXTENSION,
    [ActionType.DELETE]: LoginCheckType.EXTENSION,
  },
  [IPlatforms.ETSY]: {
    [ActionType.CROSSLIST]: LoginCheckType.EXTENSION,
    [ActionType.IMPORT]: LoginCheckType.EXTENSION,
    [ActionType.DELETE]: LoginCheckType.EXTENSION,
    [ActionType.DELIST]: LoginCheckType.EXTENSION,
  },
  [IPlatforms.DEPOP]: {
    [ActionType.CROSSLIST]: LoginCheckType.EXTENSION,
    [ActionType.IMPORT]: LoginCheckType.EXTENSION,
    [ActionType.DELETE]: LoginCheckType.EXTENSION,
  },
  [IPlatforms.GRAILED]: {
    [ActionType.CROSSLIST]: LoginCheckType.EXTENSION,
    [ActionType.IMPORT]: LoginCheckType.EXTENSION,
    [ActionType.DELETE]: LoginCheckType.EXTENSION,
  },
  [IPlatforms.SHOPIFY]: {
    [ActionType.CROSSLIST]: LoginCheckType.EXTENSION,
    [ActionType.IMPORT]: LoginCheckType.EXTENSION,
    [ActionType.DELETE]: LoginCheckType.EXTENSION,
  },
  [IPlatforms.POSHMARKUS]: {
    [ActionType.RELIST]: LoginCheckType.EXTENSION,
    [ActionType.CROSSLIST]: LoginCheckType.EXTENSION,
    [ActionType.DUPLICATE]: LoginCheckType.EXTENSION,
    [ActionType.IMPORT]: LoginCheckType.EXTENSION,
    [ActionType.DELETE]: LoginCheckType.EXTENSION,
    [ActionType.DELIST]: LoginCheckType.EXTENSION,
  },
  [IPlatforms.POSHMARKCA]: {
    [ActionType.RELIST]: LoginCheckType.EXTENSION,
    [ActionType.CROSSLIST]: LoginCheckType.EXTENSION,
    [ActionType.DUPLICATE]: LoginCheckType.EXTENSION,
    [ActionType.IMPORT]: LoginCheckType.EXTENSION,
    [ActionType.DELETE]: LoginCheckType.EXTENSION,
    [ActionType.DELIST]: LoginCheckType.EXTENSION,
  },
};

// Generate eBay platform configs separately
const ebayPlatformConfig = Object.values(EbayCountry)
  .filter((country) => country !== EbayCountry.motors_us)
  .reduce((acc, country) => {
    const marketplace = EbaySiteMapper.getMarketplaceFromCountry(country);
    const platformKey = marketplace as unknown as IPlatforms;
    acc[platformKey] = generateEbayPlatformConfig(platformKey);
    return acc;
  }, {} as Record<IPlatforms, PlatformActionConfig>);

// Merge base config with eBay config
const platformConfig: PlatformConfigMap = {
  ...basePlatformConfig,
  ...ebayPlatformConfig,
};

export interface LoginStatusOptions {
  checkExtensionLoginStatus?: boolean;
}

export default function usePlatformLoginStatusCheck() {
  const myShops = useSelector((state: IRootState) => state.myShops);
  const { allShops } = useSelector((state: IRootState) => state.myShopsAPITable);

  const isApiLoggedIn = (platform: IPlatforms): boolean =>
    allShops
      ? allShops.some(
          (shop) =>
            // Normally, this condition is correct, but we commented it out because we don't have a country-specific flow for eBay CA and eBay US. We might consider re-adding this when introducing new platforms.
            // shop.marketplace.toLowerCase().includes(platform.toLowerCase()) &&
            shop.connectionStatus === ConnectionStatus.Connected
        )
      : false;

  const isExtensionLoggedIn = (platform: IPlatforms): boolean =>
    myShops[platform]?.isLoggedIn || false;

  const getLoginStatusOfMarketplace = (
    actionType: ActionType,
    platform: IPlatforms,
    options: LoginStatusOptions = {}
  ): boolean => {
    const platformActions = platformConfig[platform];
    if (!platformActions || !(actionType in platformActions)) {
      return false; // Action not defined for this platform
    }

    const checkType = platformActions[actionType];

    switch (checkType) {
      case LoginCheckType.API:
        return isApiLoggedIn(platform);
      case LoginCheckType.EXTENSION:
        return isExtensionLoggedIn(platform);
      case LoginCheckType.BOTH:
        // In some cases we need to differentiate between the two login types
        // Check API for source platform, extension for target platform
        return options.checkExtensionLoginStatus
          ? isExtensionLoggedIn(platform)
          : isApiLoggedIn(platform);
      default:
        return false;
    }
  };

  return { getLoginStatusOfMarketplace };
}
