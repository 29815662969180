import { alpha, Link, Stack } from '@mui/material';
import { useSelector } from 'react-redux';

import Label from 'src/components/label/Label';

import { DataGridRefsColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import { getMarketplaceColor } from 'src/pages/dashboard/Tasks/helpers/getMarketplaceColor';
import { IRootState } from 'src/store';
import { getMarketplaceDisplayName, Marketplace } from '@beta.limited/primelister';

export default function DataGridMarketplaceColumn({
  marketplaceSources,
}: DataGridRefsColumnInterface) {
  const { dense } = useSelector((state: IRootState) => state.inventory);
  return (
    <Stack
      spacing={dense ? 0.25 : 0.5}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '125px',
        ml: 1,
        py: dense && Object.keys(marketplaceSources).length > 1 ? '4px' : 0,
      }}
    >
      {Object.keys(marketplaceSources) &&
        Object.keys(marketplaceSources).map((item, index) => (
          <Link
            key={Math.random() * 10000}
            href={marketplaceSources[item].listingLink}
            sx={{ textDecoration: 'none !important' }}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
          >
            <Label
              variant="soft"
              sx={{
                color: getMarketplaceColor(item.toLowerCase()),
                backgroundColor: alpha(getMarketplaceColor(item.toLowerCase()), 0.16),
                textTransform: 'none',
                cursor: 'pointer',
                letterSpacing: '0.04rem',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              {getMarketplaceDisplayName(item as Marketplace)}
            </Label>
          </Link>
        ))}
    </Stack>
  );
}
