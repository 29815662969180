import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';
import { IAutomationsState } from 'src/store/automations/slices/automationsSlice';
import { IMyClosetState } from 'src/store/automations/slices/myClosetSlice';
import { IEbayMyShopsState } from 'src/store/automations/ebay/slices/myShopsSlice.ebay';
import { IEbayAutomationsState } from 'src/store/automations/ebay/slices/automationsSlice.ebay';
import { AutomationSettingsKey } from 'src/store/automations/types';
import { IStatisticsState } from 'src/store/automations/slices/statisticsSlice';
import { IEbayStatisticsState } from 'src/store/automations/ebay/slices/statisticsSlice.ebay';
import { IEbayActivityState } from 'src/store/automations/ebay/slices/activitySlice.ebay';
import { IActivityState } from 'src/store/automations/slices/activitySlice';

export enum AutomationPlatform {
  POSHMARK = 'poshmark',
  EBAY = 'ebay',
}

type PlatformStates = {
  poshmark: {
    automations: IAutomationsState;
    myShops: IMyClosetState;
    statistics: IStatisticsState;
    activity: IActivityState;
  };
  ebay: {
    automations: IEbayAutomationsState;
    myShops: IEbayMyShopsState;
    statistics: IEbayStatisticsState;
    activity: IEbayActivityState;
  };
};

/**
 * This hook provides a unified way to access platform-specific state and functionality across different automation platforms.
 * We use it to handle common automation logic that needs to work across multiple platforms (like eBay and Poshmark).
 *
 * It abstracts away platform differences by:
 * 1. Determining the current platform based on the URL
 * 2. Providing access to the relevant state slices for that platform
 * 3. Returning a consistent interface regardless of platform
 *
 * This allows components to work with automation data without needing to know which specific platform they're dealing with.
 */
export const useAutomationPlatform = () => {
  const location = useLocation();
  const ebayAutomations = useSelector((state: IRootState) => state.ebayAutomations);
  const ebayMyShops = useSelector((state: IRootState) => state.ebayMyShops);
  const poshmarkAutomations = useSelector((state: IRootState) => state.automations);
  const poshmarkMyShops = useSelector((state: IRootState) => state.myCloset);
  const poshmarkStatistics = useSelector((state: IRootState) => state.statistics);
  const ebayStatistics = useSelector((state: IRootState) => state.ebayStatistics);
  const poshmarkActivity = useSelector((state: IRootState) => state.activity);
  const ebayActivity = useSelector((state: IRootState) => state.ebayActivity);

  const platformStates: PlatformStates = {
    poshmark: {
      automations: poshmarkAutomations,
      myShops: poshmarkMyShops,
      statistics: poshmarkStatistics,
      activity: poshmarkActivity,
    },
    ebay: {
      automations: ebayAutomations,
      myShops: ebayMyShops,
      statistics: ebayStatistics,
      activity: ebayActivity,
    },
  };

  const getCurrentPlatform = (): AutomationPlatform => {
    if (
      location.pathname.includes(PATH_AUTOMATIONS.ebayRoot) ||
      location.pathname.includes(PATH_DASHBOARD.subscription.ebayAutomation)
    ) {
      return AutomationPlatform.EBAY;
    }
    return AutomationPlatform.POSHMARK;
  };

  const platform = getCurrentPlatform();

  return {
    platform,
    states: platformStates[platform],
  };
};
