import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import { setIsUserBlockStateDialogOpen } from 'src/store/dashboard/slices/authSlice';

export const useBlockState = () => {
  const { blockState } = useSelector((state: IRootState) => state.auth);
  const dispatch = useDispatch();
  const isBlocked = blockState === 1 || blockState === 2;

  const handleBlockState = (callback: () => void) => {
    if (isBlocked) {
      dispatch(setIsUserBlockStateDialogOpen(true));
      return;
    }
    callback();
  };

  return {
    isBlocked,
    handleBlockState,
  };
};
