import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

export const useFingerprint = () => {
  const { isLoading, error, data, getData } = useVisitorData(
    { extendedResult: true },
    { immediate: true }
  );

  return {
    isLoading,
    error,
    visitorId: data?.visitorId,
    visitorData: data,
    reloadData: () => getData({ ignoreCache: true }),
  };
};

export default useFingerprint;
