import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isLoggedIn } from 'src/utils/isLoggedIn';
import useUserFirstName from 'src/hooks/useUserFirstName';
import Lottie from 'lottie-react';
import ratingMessagesAnimation from './rating-messages.json';
import { isBefore, sub } from 'date-fns'; // Added date-fns imports
import {
  IMixpanelMobileAppReviewData,
  MixpanelEventTypes,
  mixpanelTrack,
} from 'src/utils/mixpanel';
import useLocalStorage from 'src/hooks/useLocalStorage';
import {
  UserTagTypesEnum,
  setUserTagsRequest,
} from 'src/store/automations/slices/automationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { WebViewMessage } from 'src/pages/automations/AutomationsPage/types/types';
import { MobileAppMessageChannel } from 'src/utils/MobileAppMessageChannel';
import AnimatedRating from './AnimatedRating';

interface RatingPopupProps {
  onClose: () => void;
}

const mobileAppMessageChannel = new MobileAppMessageChannel();

const RatingPopup: React.FC<RatingPopupProps> = ({ onClose }) => {
  const { showMobileAppReviewDialog } = useSelector((state: IRootState) => state.automations);
  const [rating, setRating] = useState<number | null>(null);
  const [feedback, setFeedback] = useState<string>('');
  const { fullName, userId } = isLoggedIn();
  const firstName = useUserFirstName(fullName);
  const [ratingPopupDisplayedAt, setRatingPopupDisplayedAt] = useLocalStorage(
    'ratingPopupDisplayedAt',
    null
  );
  const dispatch = useDispatch();
  const [isCrispOpen, setIsCrispOpen] = useState<boolean>(
    document.body.classList.contains('crisp-chat-open')
  );

  useEffect(() => {
    // Initial check
    setIsCrispOpen(document.body.classList.contains('crisp-chat-open'));

    // Create observer to watch for class changes on body
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          setIsCrispOpen(document.body.classList.contains('crisp-chat-open'));
        }
      });
    });

    // Start observing
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class'],
    });

    // Cleanup
    return () => observer.disconnect();
  }, []);

  const [hoverEffectDone, setHoverEffectDone] = useState<boolean>(false);

  const handleRatingChange = (event: React.ChangeEvent<{}>, newValue: number | null) => {
    if (hoverEffectDone) {
      // Ensure user can change rating after initial animation
      setRating(newValue || null);
    }
  };

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (hoverEffectDone) {
      setFeedback(event.target.value);
    }
  };

  const ratingIsFourOrAbove = rating && rating >= 4;
  const ratingIsThreeOrBelow = rating && rating <= 3;

  const handleSave = () => {
    const mixpanelData: IMixpanelMobileAppReviewData = {
      rateScore: rating ?? 0,
    };
    if (ratingIsFourOrAbove) {
      const inAppReviewMessage = {
        type: WebViewMessage.Type.IN_APP_REVIEW_TRIGGER,
        payload: {
          reviewType: WebViewMessage.ReviewType.STORE,
        },
      };
      mobileAppMessageChannel.sendInAppReviewMessage(inAppReviewMessage);
    }

    if (ratingIsThreeOrBelow) {
      mixpanelData.feedbackText = feedback;
    }

    mixpanelTrack(MixpanelEventTypes.MOBILE_APP_REVIEW, mixpanelData);

    dispatch(
      setUserTagsRequest({
        userId,
        userTagType: UserTagTypesEnum.MOBILE_APP_RATE_SUBMITTED,
      })
    );
    onClose();
  };

  const handleNotNow = () => {
    const ratingPopupDisplayedAt = new Date().getTime();
    setRatingPopupDisplayedAt(ratingPopupDisplayedAt);
    onClose();
  };

  const shouldShowPopup = () => {
    if (ratingPopupDisplayedAt) {
      const sevenDaysAgo = sub(new Date(), { days: 7 });
      return isBefore(new Date(parseInt(ratingPopupDisplayedAt)), sevenDaysAgo);
    }
    return true;
  };

  if (!shouldShowPopup()) {
    return null;
  }

  return (
    <Dialog
      className="rating-popup"
      sx={{
        '& .MuiDialog-container': {
          display: isCrispOpen ? 'none' : 'flex',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '380px',
          },
        },
      }}
      open={showMobileAppReviewDialog}
      onClose={onClose}
    >
      <DialogTitle>
        <Lottie
          animationData={ratingMessagesAnimation}
          loop={true}
          style={{
            height: 200,
          }}
        />
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 600,
          }}
        >
          {firstName}, rate your PrimeLister experience?{' '}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 600,
          }}
        >
          Share your thoughts!
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: 'center',
        }}
      >
        <AnimatedRating
          rating={rating ?? 0}
          setRating={setRating}
          setHoverEffectDone={setHoverEffectDone}
          handleRatingChange={handleRatingChange}
        />
        {ratingIsThreeOrBelow && hoverEffectDone && (
          <TextField
            multiline
            sx={{
              mt: 2,
            }}
            rows={4}
            placeholder="Tell us what disappointed you..."
            variant="outlined"
            fullWidth
            value={feedback}
            onChange={handleFeedbackChange}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Stack
          sx={{
            width: '100%',
          }}
        >
          <Button
            sx={{
              borderRadius: '32px',
              p: 1.5,
            }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSave}
            disabled={!hoverEffectDone || !rating}
          >
            Rate Us
          </Button>
          <Button
            sx={{
              mt: 1,
              color: 'slategray',
              fontWeight: 300,
            }}
            onClick={handleNotNow}
          >
            Not now
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default RatingPopup;
