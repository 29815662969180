import { useEffect, useState } from 'react';
import AnnouncementAlert, { ProductTypeEnum } from './AnnouncementAlert';
import { getAnnouncementsRequest } from 'src/store/dashboard/slices/accountSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { Stack } from '@mui/material';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';

export default function AnnouncementAlertContainer({ hasAnyAlert }: { hasAnyAlert: boolean }) {
  const { announcements: data } = useSelector((state: IRootState) => state.account);

  if (
    !data.announcements.length ||
    window.location.pathname.includes(PATH_DASHBOARD.subscription.root)
  )
    return null;

  const isMyShopsOrClosetPage =
    window.location.pathname.includes(PATH_AUTOMATIONS.ebayMyShops) ||
    window.location.pathname.includes(PATH_AUTOMATIONS.myCloset);
  const isActivityPage =
    window.location.pathname.includes(PATH_AUTOMATIONS.activity) ||
    window.location.pathname.includes(PATH_AUTOMATIONS.ebayActivity);

  const bottomHasPadding = isActivityPage || isMyShopsOrClosetPage;
  return (
    <Stack
      maxWidth={900}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        paddingBottom: bottomHasPadding ? 1 : 0,
        marginTop: hasAnyAlert ? 1.2 : 0,
        gap: 0.4,
      }}
    >
      {data.announcements.map((announcement) => (
        <AnnouncementAlert
          expireDate={announcement.expireDate}
          shortMessage={announcement.shortMessage}
          products={announcement.products as ProductTypeEnum[]}
          key={announcement.id}
          message={announcement.message}
        />
      ))}
    </Stack>
  );
}
