import { alpha } from '@mui/material';

import Label from 'src/components/label/Label';
import { getMarketplaceColor } from 'src/pages/dashboard/Tasks/helpers/getMarketplaceColor';

import { DataGridMarketplaceColumnInterface } from '../../../../types/types';
import { taskPlatformOptions } from '../Columns';
import { getMarketplaceDisplayName, Marketplace } from '@beta.limited/primelister';

export default function DataGridMarketplaceColumn({
  marketplace,
}: DataGridMarketplaceColumnInterface) {
  const marketplaceColor = getMarketplaceColor(marketplace.toLowerCase());
  const marketplaceLabel = getMarketplaceDisplayName(marketplace as Marketplace);

  return (
    <Label
      variant="soft"
      sx={{
        color: marketplaceColor,
        backgroundColor: alpha(marketplaceColor, 0.16),
        textTransform: 'none',
        ml: 1,
      }}
    >
      {marketplaceLabel}
    </Label>
  );
}
