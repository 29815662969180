import {
  Marketplace,
  EbayCountry,
  getMarketplaceDisplayName,
  EbaySiteMapper,
} from '@beta.limited/primelister';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClosetDetails } from 'src/pages/automations/MyClosetPage/utils/types';

export enum ConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Removed = 'removed',
}

export const ebayCountryDisplayNames: Partial<Record<EbayCountry, string>> = {
  [EbayCountry.us]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.us)
  ),
  [EbayCountry.ca]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.ca)
  ),
  [EbayCountry.au]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.au)
  ),
  [EbayCountry.at]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.at)
  ),
  [EbayCountry.be]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.be)
  ),
  [EbayCountry.ch]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.ch)
  ),
  [EbayCountry.de]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.de)
  ),
  [EbayCountry.es]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.es)
  ),
  [EbayCountry.fr]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.fr)
  ),
  [EbayCountry.gb]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.gb)
  ),
  [EbayCountry.hk]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.hk)
  ),
  [EbayCountry.ie]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.ie)
  ),
  [EbayCountry.in]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.in)
  ),
  [EbayCountry.it]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.it)
  ),
  [EbayCountry.my]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.my)
  ),
  [EbayCountry.nl]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.nl)
  ),
  [EbayCountry.ph]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.ph)
  ),
  [EbayCountry.pl]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.pl)
  ),
  [EbayCountry.sg]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.sg)
  ),
  [EbayCountry.tw]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.tw)
  ),
  [EbayCountry.vn]: getMarketplaceDisplayName(
    EbaySiteMapper.getMarketplaceFromCountry(EbayCountry.vn)
  ),
};
export interface IRemoveData {
  id: string;
  name: string;
}
export interface IEbayMyShopsState {
  loading: boolean;
  initialLoading: boolean;
  error: string;
  isConnectDialogOpen: boolean;
  connectionType: 'connect' | 'reconnect';
  isReconnection: boolean;
  closetList: IClosetDetails[];
  hasCloset: boolean;
  hasConnection: boolean;
  hasPoshmarkSubscription: boolean;
  shouldShowConnectFormAlert: boolean;
  selectedCloset: IClosetDetails; //-- This is for the closet page, connection cases
  activeCloset: IClosetDetails; //-- This is for the automation, activity and statistics pages
  activeClosetCredentialsId: string;
  isGetClosetRequestCompleted: boolean;
  connectedShopsCount: number;
  isClosetPopoverOpen: HTMLElement | null;
  isUpgradeSubscriptionDialogOpen: boolean;
  isRemoveClosetDialogOpen: boolean;
  removeClosetData: IRemoveData;
  hasActiveClosetConnection: boolean;
  shouldOpenExpiredSubscriptionDialog: boolean;
  isConnectRemovedClosetDialogOpen: boolean;
  hasEbaySubscription: boolean;
}

const initialState = {
  isGetClosetRequestCompleted: false,
  initialLoading: true,
  loading: false,
  error: '',
  isConnectDialogOpen: false,
  connectionType: 'connect',
  isReconnection: false,
  closetList: [] as IClosetDetails[],
  hasCloset: true,
  hasConnection: true,
  hasPoshmarkSubscription: false,
  shouldShowConnectFormAlert: false,
  selectedCloset: {} as IClosetDetails,
  activeCloset: {} as IClosetDetails,
  activeClosetCredentialsId: '',
  connectedShopsCount: 0,
  isClosetPopoverOpen: null,
  isUpgradeSubscriptionDialogOpen: false,
  isRemoveClosetDialogOpen: false,
  removeClosetData: {} as IRemoveData,
  hasActiveClosetConnection: false,
  shouldOpenExpiredSubscriptionDialog: false,
  isConnectRemovedClosetDialogOpen: false,
  hasEbaySubscription: false,
};

const myClosetSlice = createSlice({
  name: 'ebayMyShopsStore',
  initialState,
  reducers: {
    setInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.initialLoading = action.payload;
    },
    setIsReconnection: (state, action: PayloadAction<boolean>) => {
      state.isReconnection = action.payload;
    },
    setIsConnectDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isConnectDialogOpen = action.payload;
    },
    setHasPoshmarkSubscription: (state, action: PayloadAction<boolean>) => {
      state.hasPoshmarkSubscription = action.payload;
    },
    setHasEbaySubscription: (state, action: PayloadAction<boolean>) => {
      state.hasEbaySubscription = action.payload;
    },
    setShouldShowConnectFormAlert: (state, action: PayloadAction<boolean>) => {
      state.shouldShowConnectFormAlert = action.payload;
    },
    setSelectedCloset: (state, action: PayloadAction<IClosetDetails>) => {
      state.selectedCloset = action.payload;
    },
    setActiveCloset: (state, action: PayloadAction<IClosetDetails>) => {
      state.activeCloset = action.payload;
      state.hasActiveClosetConnection = action.payload?.status === ConnectionStatus.Connected;
      state.activeClosetCredentialsId = action.payload?._id;
    },

    setIsClosetPopoverMenuOpen: (state, action) => {
      state.isClosetPopoverOpen = action.payload;
    },
    setIsUpgradeSubscriptionDialogOpen: (state, action) => {
      state.isUpgradeSubscriptionDialogOpen = action.payload;
    },
    setIsRemoveClosetDialogOpen: (
      state,
      action: PayloadAction<{ openDialog: boolean; removeClosetData: { name: string; id: string } }>
    ) => {
      state.isRemoveClosetDialogOpen = action.payload.openDialog;
      state.removeClosetData = action.payload.removeClosetData;
    },
    setShouldOpenExpiredSubscriptionDialog: (state, action) => {
      state.shouldOpenExpiredSubscriptionDialog = action.payload;
    },
    setIsConnectRemovedClosetDialogOpen: (state, action) => {
      state.isConnectRemovedClosetDialogOpen = action.payload;
    },
    //--
    getShopsListRequest: (state) => {
      state.loading = true;
      state.error = '';
    },
    getShopsListSuccess: (state, action: PayloadAction<IClosetDetails[]>) => {
      const shopsList = action.payload;

      state.loading = false;
      state.initialLoading = false;
      state.error = '';
      state.closetList = shopsList;
      state.hasCloset =
        shopsList.filter((shop: IClosetDetails) => shop?.status !== ConnectionStatus.Removed)
          .length > 0;
      state.isGetClosetRequestCompleted = true;
      state.connectedShopsCount = shopsList.length;
      state.hasConnection = !action.payload.filter(
        (shop) =>
          shop?.status === ConnectionStatus.Disconnected ||
          shop?.status === ConnectionStatus.Removed
      ).length;

      if (!shopsList.length) {
        state.activeCloset = {} as IClosetDetails;
      }
    },
    getShopsListFail: (state, action) => {
      state.loading = false;
      state.initialLoading = false;
      state.error = action.payload;
      state.isGetClosetRequestCompleted = true;
    },
    //--
    connectClosetRequest: (
      state,
      action: PayloadAction<{ country: string; userHandle: string; password: string }>
    ) => {
      state.loading = true;
      state.error = '';
    },
    connectClosetSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    connectClosetFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    removeClosetRequest: (state, action: PayloadAction<IRemoveData>) => {
      state.loading = true;
      state.error = '';
    },
    removeClosetSuccess: (state) => {
      state.loading = false;
      state.error = '';
      state.isRemoveClosetDialogOpen = false;
      state.removeClosetData = {} as IRemoveData;
    },
    removeClosetFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetMyClosetState: (state) => initialState,
    addEbayShopRequest: (state, action: PayloadAction<{ code: string }>) => {
      state.loading = true;
      state.error = '';
    },
    addEbayShopSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    addEbayShopFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getEbayAuthUrlRequest: (state, action: PayloadAction<{ country: string }>) => {
      state.loading = true;
      state.error = '';
    },
    getEbayAuthUrlSuccess: (state, action: PayloadAction<{ url: string }>) => {
      state.loading = false;
      state.error = '';
      window.open(action.payload.url, '_self');
    },
    getEbayAuthUrlFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetMyClosetState,
  setInitialLoading,
  setIsConnectDialogOpen,
  setIsReconnection,
  setHasPoshmarkSubscription,
  setHasEbaySubscription,
  setShouldShowConnectFormAlert,
  setSelectedCloset,
  setActiveCloset,
  setIsClosetPopoverMenuOpen,
  setIsUpgradeSubscriptionDialogOpen,
  setIsRemoveClosetDialogOpen,
  setShouldOpenExpiredSubscriptionDialog,
  setIsConnectRemovedClosetDialogOpen,
  //--
  getShopsListRequest,
  getShopsListSuccess,
  getShopsListFail,
  //--
  connectClosetRequest,
  connectClosetSuccess,
  connectClosetFail,
  //--
  removeClosetRequest,
  removeClosetSuccess,
  removeClosetFail,
  addEbayShopRequest,
  addEbayShopSuccess,
  addEbayShopFail,
  getEbayAuthUrlRequest,
  getEbayAuthUrlSuccess,
  getEbayAuthUrlFail,
} = myClosetSlice.actions;

export default myClosetSlice.reducer;
