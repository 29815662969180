// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// toastify styles
import 'react-toastify/dist/ReactToastify.min.css';

// ----------------------------------------------------------------------
import { LicenseInfo } from '@mui/x-license-pro';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, ScrollRestoration } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
// components
import { SettingsProvider } from './components/settings';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

//
import App from './App';
// ----------------------------------------------------------------------
import { Provider as ReduxProvider } from 'react-redux';
import store from './store';
import { ExtensionMessageChannel } from './utils/ExtensionMessageChannel';
import { FLAGSMITH_ENVIRONMENT, MIXPANEL_KEY, SENTRY_ENV } from './config';
import packageJson from '../package.json'; // Update the path accordingly
import { ToastContainer } from 'react-toastify';
import TagManager from 'react-gtm-module';
import SupabaseBroadcast from './utils/SupabaseBroadcast';
// import { FlagsmithProvider } from 'flagsmith/react';
// import flagsmith from 'flagsmith';
import mixpanel from 'mixpanel-browser';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axiosInstance from './utils/axios';

// Access the version from the package.json
const { version } = packageJson;
declare global {
  interface Window {
    $crisp: any[] | any;
    CRISP_WEBSITE_ID: string;
    CRISP_TOKEN_ID: string | null;
    CRISP_RUNTIME_CONFIG: {
      session_merge: boolean;
    };
    extension: ExtensionMessageChannel;
    supabase: SupabaseBroadcast;
    ReactNativeWebView: any;
    Tally: any;
    CRISP_READY_TRIGGER: () => void;
  }
}

//----------------------------------------------------------------------

const tagManagerArgs = {
  gtmId: 'GTM-MHJX2Z5',
};

TagManager.initialize(tagManagerArgs);

// ----------------------------------------------------------------------

interface SentryFilterConfig {
  errors: string[];
}

const initSentry = async () => {
  try {
    const { data: sentryFilterConfig } = await axiosInstance.get<SentryFilterConfig>(
      'error-texts/sentry'
    );
    Sentry.init({
      dsn: 'https://694e9f7ede3f440ab066113558e0f4dc@o4503902346477568.ingest.sentry.io/4505039614509056',
      release: version,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          //@ts-ignore
          networkDetailAllowUrls: [
            window.location.origin,
            'sandbox-api.primelister.com',
            'api.primelister.com',
            'app.primelister.com',
            'sandbox.primelister.com',
          ],
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
          networkCaptureBodies: true,
        }),
      ],
      beforeSend(event, hint) {
        const error = hint.originalException as Error | { message: string };
        if (error && typeof error === 'object' && 'message' in error) {
          for (const errorFilterText of sentryFilterConfig.errors) {
            if (error.message.toLowerCase().includes(errorFilterText.toLowerCase())) {
              console.warn(
                `Error filter rule: ${errorFilterText} | Supressed error: ${error.message}`
              );
              return null;
            }
          }
        }
        return event;
      },
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: SENTRY_ENV,
    });
  } catch (error) {
    console.error('Failed to initialize Sentry:', error);
  }
};

// Initialize Sentry
initSentry();

mixpanel.init(MIXPANEL_KEY as string, {
  debug: false,
  track_pageview: false,
  persistence: 'localStorage',
});

// ----------------------------------------------------------------------
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY || '');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ReduxProvider store={store}>
    <HelmetProvider>
      <SettingsProvider>
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
          <BrowserRouter>
            <ToastContainer />
            <FpjsProvider
              loadOptions={{
                apiKey: import.meta.env.VITE_FINGERPRINT_KEY as string,
              }}
            >
              <App />
            </FpjsProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </SettingsProvider>
    </HelmetProvider>
  </ReduxProvider>
);
