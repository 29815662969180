import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import {
  setHasUserChangedTheSliderValue,
  setIsClosetSelectionErrorDialogOpen,
  setSliderValue,
} from 'src/store/dashboard/slices/pricingSlice';
import { IRootState } from 'src/store';
import { PATH_AUTOMATIONS } from 'src/routes/paths';
import { useNavigate } from 'react-router';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';

const ClosetSelectionErrorDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    platform,
    states: {
      myShops: { connectedShopsCount },
    },
  } = useAutomationPlatform();

  const { isClosetSelectionErrorDialogOpen, sliderValue } = useSelector(
    (state: IRootState) => state.pricing
  );

  const shopWord = platform === AutomationPlatform.EBAY ? 'shop' : 'closet';
  const shopsWord = platform === AutomationPlatform.EBAY ? 'shops' : 'closets';

  const handleClose = () => {
    dispatch(setIsClosetSelectionErrorDialogOpen(false));
    dispatch(setSliderValue(connectedShopsCount));
    dispatch(setHasUserChangedTheSliderValue(false));
  };

  const navigateToClosetPage = () => {
    navigate(PATH_AUTOMATIONS.myCloset);
    handleClose();
  };

  const errorMessage = [
    `Oops! You've tried to select ${sliderValue} ${shopsWord}, but you cannot choose fewer ${shopsWord} than the number currently connected to your account.`,
    `You currently have ${connectedShopsCount} ${shopsWord} connected, and their slots cannot be changed.`,
  ];

  return (
    <Dialog fullWidth maxWidth="sm" open={isClosetSelectionErrorDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 3 }}>
        {shopWord.charAt(0).toUpperCase() + shopWord.slice(1)} Selection Error
      </DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        {errorMessage.map((message, index) => (
          <Typography
            sx={{
              ...(index < 2 && { pb: 3 }),
              fontSize: '16px',
              fontWeight: '400',
              color: '#637381',
            }}
            key={index}
          >
            {message}
          </Typography>
        ))}
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '400',
            color: '#637381',
          }}
        >
          If you prefer to pay for fewer {shopsWord}, you have the option to{' '}
          <u style={{ cursor: 'pointer' }} onClick={navigateToClosetPage}>
            {' '}
            remove {shopsWord}{' '}
          </u>{' '}
          from your account.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ClosetSelectionErrorDialog;
