import { IS_SANDBOX } from 'src/config';

export const getCrosslistMonthlyPlans = (isThereAnyActivePlan: boolean) => {
  const crosslistMonthlyPlans = [
    {
      planName: 'Auto Relister Monthly Plan',
      subscription: 'Basic',
      price: 29.99,
      labelAction: isThereAnyActivePlan ? 'Update Plan' : 'Subscribe Now',
      caption: 'per user/month, when billed yearly',
      planId: 'plan-monthly',
      stripePlanId: IS_SANDBOX ? 'price_1HqaWGJjmpbiai5UGhweZjA1' : 'plan_H5nS5kcq9qwDqI',
      subscriptionName: 'Crosspost Subscription',
      lists: [
        {
          text: 'Auto Re-listing',
          isAvailable: true,
        },
        {
          text: 'Listing Tasks',
          isAvailable: true,
        },
        {
          text: 'Scheduled Tasks',
          isAvailable: true,
        },
        {
          text: 'Bulk Tasks',
          isAvailable: true,
        },
        {
          text: 'Crosslisting',
          isAvailable: false,
        },
        {
          text: 'Inventory Management',
          isAvailable: false,
        },
        {
          text: 'Tags & Groups',
          isAvailable: false,
        },
      ],
    },
    {
      planName: 'Crosspost + Auto Relister Monthly Plan',
      subscription: 'Pro',
      price: 49.99,
      labelAction: isThereAnyActivePlan ? 'Update Plan' : 'Subscribe Now',
      caption: 'per user/month, when billed yearly',
      planId: 'crosspost-plan-monthly',
      stripePlanId: IS_SANDBOX
        ? 'price_1HqaVVJjmpbiai5UI5IkbLYL'
        : 'price_1HowCyJjmpbiai5UwS1Ho2OQ',
      subscriptionName: 'Crosspost Subscription',
      lists: [
        {
          text: 'Auto Re-listing',
          isAvailable: true,
        },
        {
          text: 'Listing Tasks',
          isAvailable: true,
        },
        {
          text: 'Scheduled Tasks',
          isAvailable: true,
        },
        {
          text: 'Bulk Tasks',
          isAvailable: true,
        },
        {
          text: 'Crosslisting',
          isAvailable: true,
        },
        {
          text: 'Inventory Management',
          isAvailable: true,
        },
        {
          text: 'Tags & Groups',
          isAvailable: true,
        },
      ],
    },
  ];
  return crosslistMonthlyPlans;
};
export const getCrosslistAnnualPlans = (isThereAnyActivePlan: boolean) => {
  const crosslistAnnualPlans = [
    {
      planName: 'Auto Relister Annual Plan',
      subscription: 'Basic',
      price: 20.83,
      annualPrice: 249.99,
      labelAction: isThereAnyActivePlan ? 'Update Plan' : 'Subscribe Now',
      caption: 'per user/month, when billed yearly',
      planId: 'plan-annual',
      stripePlanId: IS_SANDBOX ? 'price_1HqaWGJjmpbiai5Uhx53Wjq2' : 'plan_H5nUT1K9mGW4Wv',
      subscriptionName: 'Crosspost Subscription',
      lists: [
        {
          text: 'Auto Re-listing',
          isAvailable: true,
        },
        {
          text: 'Listing Tasks',
          isAvailable: true,
        },
        {
          text: 'Scheduled Tasks',
          isAvailable: true,
        },
        {
          text: 'Bulk Tasks',
          isAvailable: true,
        },
        {
          text: 'Crosslisting',
          isAvailable: false,
        },
        {
          text: 'Inventory Management',
          isAvailable: false,
        },
        {
          text: 'Tags & Groups',
          isAvailable: false,
        },
      ],
    },
    {
      planName: 'Crosspost + Auto Relister Annual Plan',
      subscription: 'Pro',
      price: 33.33,
      annualPrice: 399.99,
      labelAction: isThereAnyActivePlan ? 'Update Plan' : 'Subscribe Now',
      caption: 'per user/month, when billed yearly',
      planId: 'crosspost-plan-annual',
      stripePlanId: IS_SANDBOX
        ? 'price_1HqaVUJjmpbiai5UdXV6Z0ws'
        : 'price_1HowCyJjmpbiai5Uc8qvXbxL',
      subscriptionName: 'Crosspost Subscription',
      lists: [
        {
          text: 'Auto Re-listing',
          isAvailable: true,
        },
        {
          text: 'Listing Tasks',
          isAvailable: true,
        },
        {
          text: 'Scheduled Tasks',
          isAvailable: true,
        },
        {
          text: 'Bulk Tasks',
          isAvailable: true,
        },
        {
          text: 'Crosslisting',
          isAvailable: true,
        },
        {
          text: 'Inventory Management',
          isAvailable: true,
        },
        {
          text: 'Tags & Groups',
          isAvailable: true,
        },
      ],
    },
  ];

  return crosslistAnnualPlans;
};

export const getPoshmarkPlans = (isThereAnyActivePlan: boolean, poshmarkShopCapacity: number) => {
  const capacityNumber: number = poshmarkShopCapacity ? poshmarkShopCapacity : 1;
  const poshmarkFeatures = [
    {
      text: `Connect ${capacityNumber} ${capacityNumber > 1 ? 'closets' : 'closet'}`,
      isAvailable: true,
    },
    {
      text: '24/7 Cloud-Based Automations',
      isAvailable: true,
    },
    {
      text: 'Closet Share',
      isAvailable: true,
    },
    {
      text: 'Community Share',
      isAvailable: true,
    },
    {
      text: 'Re-list',
      isAvailable: true,
    },
    {
      text: 'Offer to Likers',
      isAvailable: true,
    },
    {
      text: 'Party Share',
      isAvailable: true,
    },
    {
      text: 'Return Share',
      isAvailable: true,
    },
    {
      text: 'Return Follow',
      isAvailable: true,
    },
    {
      text: 'Follow New Closets',
      isAvailable: true,
    },
  ];

  const monthlyAddOn = () => {
    if (1 < capacityNumber && capacityNumber <= 3) {
      return 20 * (capacityNumber - 1);
    }

    if (capacityNumber > 3) {
      return 20 * 2 + 18 * (capacityNumber - 3);
    }
    return 0;
  };
  const yearlyAddOn = () => {
    if (1 < capacityNumber && capacityNumber <= 3) {
      return (192 * (capacityNumber - 1)) / 12;
    }

    if (capacityNumber > 3) {
      return (192 * 2) / 12 + (173 * (capacityNumber - 3)) / 12;
    }
    return 0;
  };

  const yearlyTotalAddOn = () => {
    if (1 < capacityNumber && capacityNumber <= 3) {
      return 192 * (capacityNumber - 1);
    }

    if (capacityNumber > 3) {
      return 192 * 2 + 173 * (capacityNumber - 3);
    }
    return 0;
  };

  const poshmarkPlans = [
    {
      planName: 'Poshmark Automation Monthly Plan',
      subscription: 'MONTHLY',
      price: 25 + monthlyAddOn(),
      labelAction: isThereAnyActivePlan ? 'Update Plan' : 'Subscribe Now',
      planId: 'poshmark-cloud-plan-monthly',
      stripePlanId: IS_SANDBOX
        ? 'price_1NYVHKJjmpbiai5UfhyGzLvx'
        : 'price_1NaePHJjmpbiai5U5TpTqpOV',
      lists: poshmarkFeatures,
      subscriptionName: 'Poshmark Automation Subscription',
    },
    {
      planName: 'Poshmark Automation Annual Plan',
      subscription: 'YEARLY',
      price: 20 + yearlyAddOn(),
      annualPrice: 240 + yearlyTotalAddOn(),
      labelAction: isThereAnyActivePlan ? 'Update Plan' : 'Subscribe Now',
      caption: 'per month, when billed yearly',
      planId: 'poshmark-cloud-plan-annual',
      stripePlanId: IS_SANDBOX
        ? 'price_1NYVScJjmpbiai5U2ys8xsiy'
        : 'price_1NaePHJjmpbiai5UtOEoAlfF',
      lists: poshmarkFeatures,
      subscriptionName: 'Poshmark Automation Subscription',
    },
  ];
  return poshmarkPlans;
};

export const getEbayPlans = (isThereAnyActivePlan: boolean, ebayShopCapacity: number) => {
  const capacityNumber: number = ebayShopCapacity ? ebayShopCapacity : 1;
  const ebayFeatures = [
    {
      text: `Connect ${capacityNumber} ${capacityNumber > 1 ? 'shops' : 'shop'}`,
      isAvailable: true,
    },
    {
      text: '24/7 Cloud-Based Automations',
      isAvailable: true,
    },
    {
      text: 'Re-create',
      isAvailable: true,
    },
    {
      text: 'Offer to Watchers',
      isAvailable: true,
    },
  ];

  const monthlyAddOn = () => {
    if (capacityNumber === 1) {
      return 15;
    }
    return 15 + (12 * (capacityNumber - 1));
  };

  const yearlyAddOn = () => {
    if (capacityNumber === 1) {
      return 12;
    }
    return 12 + (9.58 * (capacityNumber - 1));
  };

  const yearlyTotalAddOn = () => {
    if (capacityNumber === 1) {
      return 144;
    }
    return 144 + (115 * (capacityNumber - 1));
  };

  const ebayPlans = [
    {
      planName: 'eBay Automation Monthly Plan',
      subscription: 'MONTHLY',
      price: monthlyAddOn(),
      labelAction: isThereAnyActivePlan ? 'Update Plan' : 'Subscribe Now',
      planId: 'ebay-cloud-plan-monthly',
      stripePlanId: IS_SANDBOX
        ? 'price_1QNBiTJjmpbiai5UVxzMNJ6s'
        : 'price_1QNBcwJjmpbiai5UZHFEa7fM',
      lists: ebayFeatures,
      subscriptionName: 'eBay Automation Subscription',
    },
    {
      planName: 'eBay Automation Annual Plan',
      subscription: 'YEARLY',
      price: yearlyAddOn(),
      annualPrice: yearlyTotalAddOn(),
      labelAction: isThereAnyActivePlan ? 'Update Plan' : 'Subscribe Now',
      caption: 'per month, when billed yearly',
      planId: 'ebay-cloud-plan-annual',
      stripePlanId: IS_SANDBOX
        ? 'price_1QNBizJjmpbiai5U6PBDdTSJ'
        : 'price_1QNBdxJjmpbiai5U9HWwPNlf',
      lists: ebayFeatures,
      subscriptionName: 'eBay Automation Subscription',
    },
  ];
  return ebayPlans;
};
