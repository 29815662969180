import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Location } from 'react-router';
import usePlatformChecker from 'src/hooks/usePlatformChecker';
import { IRootState } from 'src/store';
import {
  setHasCheckedConnection,
  setIsExtensionConnected,
  setIsExtensionConnectedResultLoading,
} from 'src/store/dashboard/slices/homeSlice';
import { setAllPlatformsInitialLoading } from 'src/store/dashboard/slices/myShopsSlice';
import { ExtensionMessageChannel } from '../utils/ExtensionMessageChannel';
import SupabaseBroadcast from '../utils/SupabaseBroadcast';

const useInitializeExtensionAndThirdParties = (location: Location, userId: string) => {
  // Initialize extension message channel
  const dispatch = useDispatch();
  const { isExtensionConnected, hasCheckedConnection, isExtensionConnectedResultLoading } =
    useSelector((state: IRootState) => state.home);

  const { checkAllPlatformsConnection } = usePlatformChecker();

  const checkAllPlatformsIfConnected = async () => {
    const isConnected = window.extension && window.extension.isConnected;

    if (isConnected) {
      await checkAllPlatformsConnection();
    } else {
      dispatch(setAllPlatformsInitialLoading(true));
      setTimeout(async () => {
        const isConnectedSecondCheck = window.extension && window.extension.isConnected;
        if (isConnectedSecondCheck) {
          await checkAllPlatformsConnection();
        } else {
          dispatch(setAllPlatformsInitialLoading(false));
        }
      }, 3000);
    }
  };

  useEffect(() => {
    window.extension = new ExtensionMessageChannel();
    window.supabase = new SupabaseBroadcast(`${userId}`);
    window.supabase.listenEvents();

    window.extension.events.addEventListener('connect', () => {
      dispatch(setIsExtensionConnected(true));
      dispatch(setHasCheckedConnection(true));
      dispatch(setIsExtensionConnectedResultLoading(false));
    });

    window.extension.events.addEventListener('disconnect', () => {
      dispatch(setIsExtensionConnected(false));
      dispatch(setHasCheckedConnection(true));
      dispatch(setIsExtensionConnectedResultLoading(false));
    });
  }, []);

  useEffect(() => {
    if (isExtensionConnected && hasCheckedConnection && !isExtensionConnectedResultLoading) {
      dispatch(setAllPlatformsInitialLoading(true));
      checkAllPlatformsIfConnected();
      setTimeout(() => {
        dispatch(setAllPlatformsInitialLoading(false));
      }, 30000);
    }
  }, [isExtensionConnected, hasCheckedConnection, isExtensionConnectedResultLoading]);
};

export default useInitializeExtensionAndThirdParties;
