import {
  IAccountUpdateData,
  INotificationRequest,
  IUserSubscriptionsList,
  IUserAccountDetail,
  IChangePassword,
} from 'src/pages/dashboard/Account/types/accountTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router';
import { IAuthState } from './authSlice';

export enum SubscriptionStatus {
  EXPIRED = 'expired',
  TRIAL = 'trial',
  CANCELED = 'canceled',
  ACTIVE = 'active',
  PAST_DUE = 'past due',
}
export interface IBillingSubscription {
  createdOn: number;
  expires: number;
  planId: string;
  planName: string;
  status: string;
  subscriptionId: string;
  subscriptionItemId: string;
  subscriptionName: string;
  taskCount: number;
  upgradable: boolean;
  nextInvoiceAmount: number;
  shopCapacity?: number;
  usedCoupon: boolean;
  invoicePaymentLink?: string;
}

interface IAffiliateSummary {
  affiliateUrl: string;
  availableToPayout: number;
  totalRewardAmount: number;
  totalPayments: number;
  totalSignUps: number;
  lastPayoutRequestDate?: string;
}

interface IAffiliateBalanceHistory {
  pageCount: number;
  count: number;
  data: {
    customer: string;
    date: string;
    event: string;
    id: string;
    reward: number;
  }[];
}
export interface ICardItem {
  brand: string;
  expMonth: number;
  expYear: number;
  id: string;
  isDefault: boolean;
  last4: string;
  email: string;
}

interface IAnnouncement {
  message: string;
  products: string[];
  expireDate: string;
  id: string;
  createdAt: number;
  updatedAt: number;
  shortMessage: string;
}

interface IAnnouncementsResponse {
  announcements: IAnnouncement[];
}

export interface IAccountState {
  loading: boolean;
  initialLoading: boolean;
  error: string;
  success: string;
  currentTab: string;
  userAccountDetail: IUserAccountDetail;
  userSubscriptionsList: IUserSubscriptionsList;
  announcements: IAnnouncementsResponse;
  isInitialDeactivateOpen: boolean;
  isChangePasswordDialogOpen: boolean;
  passwordDetails: {
    oldPassword: string;
    newPassword: string;
  };
  billingSubscriptionList: IBillingSubscription[];
  poshmarkShopCapacity: number;
  selectedSubscriptionToCancel: IBillingSubscription;
  isCancelBillingSubscriptionDialogOpen: boolean;
  billingInvoiceList: any[];
  cardList: ICardItem[];
  actionsSelectedCard: ICardItem;
  isCardActionsMenuOpen: HTMLElement | null;
  isDeleteCardDialogOpen: boolean;
  isSetDefaultCardDialogOpen: boolean;
  invoicesHasMore: boolean;
  invoicesRequestLimit: number;
  moreInvoicesLoading: boolean;
  coupon: string;
  isAffiliatePayoutDialogOpen: boolean;
  affiliateSummary: IAffiliateSummary | null;
  affiliateBalanceHistory: IAffiliateBalanceHistory | null;
  affiliateBalanceHistoryPage: number;
  affiliateBalanceHistoryPageSize: number;
  affiliateBalanceHistoryRowsPerPageOptions: number[];
  isPayoutSubmittedDialogOpen: boolean;
  isPayoutSubscriptionExpiredDialogOpen: boolean;
  ebayShopCapacity: number;
  hasSubscriptionChecked: boolean;
}

const initialState = {
  userAccountDetail: {
    name: '',
    email: '',
    timeZone: {
      gmt: '',
      name: '',
      offset: 0,
    },
    blockState: 0,
    id: '',
    proStatus: false,
    referrerCode: '',
  },
  userSubscriptionsList: {
    unsubscribedProductTips: true,
    unsubscribedErrorNotification: true,
    unsubscribedScheduleReminder: true,
    unsubscribedMissedTasks: true,
    unsubscribedNewRelease: true,
    unsubscribedAffiliateNewPayment: true,
    unsubscribedAffiliateSubPayment: true,
    unsubscribedAffiliateRefund: true,
    unsubscribedMobileSaleNotification: true,
  },
  currentTab: 'general',
  success: '',
  error: '',
  loading: false,
  initialLoading: true,
  announcements: {
    announcements: [] as IAnnouncement[],
  },
  isInitialDeactivateOpen: false,
  isChangePasswordDialogOpen: false,
  passwordDetails: {
    oldPassword: '',
    newPassword: '',
  },
  billingSubscriptionList: [] as IBillingSubscription[],
  poshmarkShopCapacity: 1,
  ebayShopCapacity: 1,
  selectedSubscriptionToCancel: {},
  isCancelBillingSubscriptionDialogOpen: false,
  billingInvoiceList: [],
  cardList: [] as ICardItem[],
  actionsSelectedCard: {},
  isCardActionsMenuOpen: null as HTMLElement | null,
  isDeleteCardDialogOpen: false,
  isSetDefaultCardDialogOpen: false,
  invoicesHasMore: false,
  invoicesRequestLimit: 10,
  moreInvoicesLoading: false,
  coupon: '',
  isAffiliatePayoutDialogOpen: false,
  affiliateSummary: null,
  affiliateBalanceHistory: null,
  affiliateBalanceHistoryPage: 1,
  affiliateBalanceHistoryPageSize: 25,
  affiliateBalanceHistoryRowsPerPageOptions: [25, 50, 100, 250, 500],
  isPayoutSubmittedDialogOpen: false,
  isPayoutSubscriptionExpiredDialogOpen: false,
  hasSubscriptionChecked: false,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setIsPayoutSubmittedDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isPayoutSubmittedDialogOpen = action.payload;
    },
    setAffiliateBalanceHistoryPage: (state, action: PayloadAction<number>) => {
      state.affiliateBalanceHistoryPage = action.payload;
    },
    setAffiliateBalanceHistoryPageSize: (state, action: PayloadAction<number>) => {
      state.affiliateBalanceHistoryPageSize = action.payload;
    },
    affiliateSummaryRequest: (state, action: PayloadAction<{ page: number; size: number }>) => {
      state.loading = true;
      state.error = '';
    },
    affiliateSummarySuccess: (state, action: PayloadAction<IAffiliateSummary>) => {
      state.loading = false;
      state.error = '';
      (state.affiliateSummary as IAffiliateSummary | null) = action.payload;
    },
    affiliateSummaryFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.affiliateSummary = null;
    },
    affiliateBalanceHistoryRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = '';
    },
    affiliateBalanceHistorySuccess: (state, action: PayloadAction<IAffiliateBalanceHistory>) => {
      state.loading = false;
      state.error = '';
      (state.affiliateBalanceHistory as IAffiliateBalanceHistory | null) = action.payload;
    },
    affiliateBalanceHistoryFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.affiliateBalanceHistory = null;
    },
    setIsAffiliatePayoutDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isAffiliatePayoutDialogOpen = action.payload;
    },
    setIsPayoutSubscriptionExpiredDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isPayoutSubscriptionExpiredDialogOpen = action.payload;
    },
    setCoupon: (state, action: PayloadAction<string>) => {
      state.coupon = action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
    setIsInitialDeactivateOpen: (state, action: PayloadAction<boolean>) => {
      state.isInitialDeactivateOpen = action.payload;
    },
    setIsChangePasswordDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isChangePasswordDialogOpen = action.payload;
    },
    setPasswordDetails: (
      state,
      action: PayloadAction<{
        oldPassword: string;
        newPassword: string;
      }>
    ) => {
      state.passwordDetails = action.payload;
    },
    setBillingSubscriptionList: (state, action: PayloadAction<IBillingSubscription[]>) => {
      state.billingSubscriptionList = action.payload;
      state.poshmarkShopCapacity = action.payload[1].shopCapacity as number;
      state.ebayShopCapacity = action.payload[2].shopCapacity as number;
    },
    setIsCancelBillingSubscriptionDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isCancelBillingSubscriptionDialogOpen = action.payload;
    },
    setSelectedSubscriptionToCancel: (state, action: PayloadAction<IBillingSubscription>) => {
      state.selectedSubscriptionToCancel = action.payload;
    },
    setBillingInvoiceList: (state, action) => {
      state.billingInvoiceList = action.payload;
    },
    setActionsSelectedCard: (state, action: PayloadAction<ICardItem>) => {
      state.actionsSelectedCard = action.payload;
    },
    setIsCardActionsMenuOpen: (state, action) => {
      state.isCardActionsMenuOpen = action.payload;
    },
    setIsDeleteCardDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isDeleteCardDialogOpen = action.payload;
    },
    setIsSetDefaultCardDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isSetDefaultCardDialogOpen = action.payload;
    },
    setInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.initialLoading = action.payload;
    },
    setInvoicesHasMore: (state, action: PayloadAction<boolean>) => {
      state.invoicesHasMore = action.payload;
    },
    setInvoicesRequestLimit: (state, action: PayloadAction<number>) => {
      state.invoicesRequestLimit = action.payload;
    },
    setHasSubscriptionChecked: (state, action: PayloadAction<boolean>) => {
      state.hasSubscriptionChecked = action.payload;
    },
    //--
    accountDetailRequest: (
      state,
      action: PayloadAction<{
        navigate: NavigateFunction;
        isFromVerifyDialog?: boolean;
        loginData?: IAuthState['loginData'];
      }>
    ) => {
      state.loading = true;
    },
    accountDetailSuccess: (state, action) => {
      state.loading = false;
      state.userAccountDetail = action.payload;
    },
    accountDetailFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    accountUpdateRequest: (state, action: PayloadAction<IAccountUpdateData>) => {
      state.loading = true;
    },
    accountUpdateSuccess: (state, action) => {
      state.loading = false;
      state.userAccountDetail.name = action.payload.name;
      state.userAccountDetail.timeZone = action.payload.timeZone;
    },
    accountUpdateFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    accountDeactivateRequest: (state, action: PayloadAction<{ navigate: NavigateFunction }>) => {
      state.loading = true;
    },
    accountDeactivateFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    accountAffiliateRequest: (state) => {
      state.loading = true;
    },
    accountAffiliateSuccess: (state) => {
      state.loading = false;
    },
    accountAffiliateFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    accountGetSubscriptionsRequest: (state) => {
      state.loading = true;
    },
    accountGetSubscriptionsSuccess: (state, action: PayloadAction<IUserSubscriptionsList>) => {
      state.loading = false;
      state.userSubscriptionsList = action.payload;
    },
    accountGetSubscriptionsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    accountSubscriptionChangeRequest: (state, action: PayloadAction<INotificationRequest>) => {
      state.loading = true;
    },
    accountSubscriptionChangeSuccess: (state, action: PayloadAction<IUserSubscriptionsList>) => {
      state.loading = false;
      state.userSubscriptionsList = action.payload;
    },
    accountSubscriptionChangeFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    accountChangePasswordRequest: (state, action: PayloadAction<IChangePassword>) => {
      state.loading = true;
    },
    accountChangePasswordSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    accountChangePasswordFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    getBillingSubscriptionsRequest: (state) => {
      state.loading = true;
      state.error = '';
    },
    getBillingSubscriptionsSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    getBillingSubscriptionsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    cancelBillingSubscriptionRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = '';
    },
    cancelBillingSubscriptionSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    cancelBillingSubscriptionFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    getBillingInvoiceListRequest: (state, action: PayloadAction<number>) => {
      state.loading = true;
      state.moreInvoicesLoading = true;
      state.error = '';
    },
    getBillingInvoiceListSuccess: (state) => {
      state.loading = false;
      state.moreInvoicesLoading = false;
      state.error = '';
    },
    getBillingInvoiceListFail: (state, action) => {
      state.loading = false;
      state.moreInvoicesLoading = false;
      state.error = action.payload;
    },
    //--
    getCardsInfoRequest: (state) => {
      state.loading = true;
      state.error = '';
    },
    getCardsInfoSuccess: (state, action: PayloadAction<ICardItem[]>) => {
      const orderedCardList = (): ICardItem[] => {
        const defaultObject = action.payload.find((obj) => obj.isDefault === true);
        if (defaultObject) {
          const nonDefaultArray = action.payload.filter((obj) => obj.isDefault === false);
          const sortedArray: ICardItem[] = [defaultObject, ...nonDefaultArray];
          return sortedArray;
        }
        return action.payload;
      };
      state.loading = false;
      state.error = '';
      state.cardList = orderedCardList();
    },
    getCardsInfoFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    deleteCardRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = '';
    },
    deleteCardSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    deleteCardFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updatePayoutRequestDateRequest: (
      state,
      action: PayloadAction<{
        affiliateBalanceHistoryPage: number;
        affiliateBalanceHistoryPageSize: number;
      }>
    ) => {
      state.loading = true;
    },
    updatePayoutRequestDateSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    updatePayoutRequestDateFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    updateCardRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = '';
    },
    updateCardSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    updateCardFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetAccountState: (state) => initialState,
    getAnnouncementsRequest: (state) => {
      state.loading = true;
      state.error = '';
    },
    getAnnouncementsSuccess: (state, action: PayloadAction<IAnnouncementsResponse>) => {
      state.loading = false;
      state.error = '';
      state.announcements = action.payload;
    },
    getAnnouncementsFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.announcements = {
        announcements: [] as IAnnouncement[],
      };
    },
  },
});

export const {
  updatePayoutRequestDateFail,
  updatePayoutRequestDateRequest,
  updatePayoutRequestDateSuccess,
  setAffiliateBalanceHistoryPage,
  setAffiliateBalanceHistoryPageSize,
  affiliateSummaryRequest,
  affiliateSummarySuccess,
  affiliateSummaryFail,
  affiliateBalanceHistoryRequest,
  affiliateBalanceHistorySuccess,
  affiliateBalanceHistoryFail,
  resetAccountState,
  setIsAffiliatePayoutDialogOpen,
  setIsPayoutSubscriptionExpiredDialogOpen,
  setCoupon,
  setCurrentTab,
  setIsInitialDeactivateOpen,
  setIsChangePasswordDialogOpen,
  setPasswordDetails,
  setIsCancelBillingSubscriptionDialogOpen,
  setBillingSubscriptionList,
  setSelectedSubscriptionToCancel,
  setBillingInvoiceList,
  setActionsSelectedCard,
  setIsCardActionsMenuOpen,
  setIsDeleteCardDialogOpen,
  setIsSetDefaultCardDialogOpen,
  setInitialLoading,
  setInvoicesHasMore,
  setInvoicesRequestLimit,
  setIsPayoutSubmittedDialogOpen,
  setHasSubscriptionChecked,
  //--
  accountDetailRequest,
  accountDetailSuccess,
  accountDetailFail,
  //--
  accountUpdateRequest,
  accountUpdateSuccess,
  accountUpdateFail,
  //--
  accountDeactivateRequest,
  accountDeactivateFail,
  accountAffiliateRequest,
  accountAffiliateSuccess,
  accountAffiliateFail,
  //--
  accountGetSubscriptionsRequest,
  accountGetSubscriptionsSuccess,
  accountGetSubscriptionsFail,
  //--
  accountSubscriptionChangeRequest,
  accountSubscriptionChangeSuccess,
  accountSubscriptionChangeFail,
  //--
  accountChangePasswordRequest,
  accountChangePasswordSuccess,
  accountChangePasswordFail,
  //--
  getBillingSubscriptionsRequest,
  getBillingSubscriptionsSuccess,
  getBillingSubscriptionsFail,
  //--
  cancelBillingSubscriptionRequest,
  cancelBillingSubscriptionSuccess,
  cancelBillingSubscriptionFail,
  //--
  getBillingInvoiceListRequest,
  getBillingInvoiceListSuccess,
  getBillingInvoiceListFail,
  //--
  getCardsInfoRequest,
  getCardsInfoSuccess,
  getCardsInfoFail,
  //--
  deleteCardRequest,
  deleteCardSuccess,
  deleteCardFail,
  //--
  updateCardRequest,
  updateCardSuccess,
  updateCardFail,
  getAnnouncementsRequest,
  getAnnouncementsSuccess,
  getAnnouncementsFail,
} = accountSlice.actions;
export default accountSlice.reducer;
