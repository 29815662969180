import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, delay, put, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import axiosInstance from 'src/utils/axios';
import { crosslistEndpoints } from 'src/endpoints';

import {
  importGetListingsRequest,
  setListingsForImport,
  importGetListingsSuccess,
  setHasMoreImportableListings,
  setImportNextPageID,
  importGetListingsFail,
  importListingsRequest,
  setSelectedImportCheckboxes,
  setImportedListingsList,
  importGetInitialStatusSuccess,
  importGetInitialStatusFail,
  importGetInitialStatusRequest,
  apiBulkImportListingsSuccess,
  apiBulkImportListingsFail,
  apiBulkImportListingsRequest,
  setInProgressList,
  setOpenImportDialog,
  setIsImportErrorDialogOpen,
  setImportLoading,
  setIsImportingListings,
} from '../slices/importTableSlice';

import {
  IGetListingsPayloadData,
  IGetListingsResponseData,
  IImportListingsPayload,
} from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import { toast } from 'react-toastify';
import {
  shapeImportInformationToStringArray,
  shapeListingsForImportCheck,
} from 'src/pages/dashboard/Inventory/helpers/helpersForImportStatusCheck';
import { IShopifyProductDetail } from 'src/pages/dashboard/MyShops/types/shopifyTypes';
import {
  MARKETPLACE_PLATFORMS,
  getCurrentShopForMarketplace,
  getShopNameAndId,
  getShopperId,
  isEbay,
} from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';
import {
  IAPIMarketplaces,
  getEbayProductsRequest,
  officialApiPlatforms,
} from '../slices/myShopsAPITableSlice';
import { IRootState } from 'src/store';
import { IUserImportJob, IUserActionQueueJobBase } from 'src/pages/dashboard/MyShops/types';
import { ActionSource, Marketplace, SubscriptionType, UserAction } from '@beta.limited/primelister';
import {
  createUserActionQueueJobRequest,
  setOpenAPIConnectionRequiredDialog,
} from '../slices/inventorySlice';
import { setIsNotLoggedInDialogOpen } from '../slices/myShopsSlice';

/** TODOs
 * When all related platforms are handled, check and adjust types used in api import case commonly and replace with the one in line 108
 **/

function* importGetListingsSaga(action: PayloadAction<IGetListingsPayloadData>) {
  try {
    const isOfficialApiPlatform = officialApiPlatforms.includes(
      action.payload.importPlatform as unknown as IAPIMarketplaces
    );
    const isPlatformEbay = isEbay(action.payload.importPlatform);

    if (!isOfficialApiPlatform) {
      const { shopId, shopName } = getShopNameAndId(
        action.payload.importPlatform as Marketplace,
        action.payload.myShopsState
      );

      const response: AxiosResponse<IGetListingsResponseData> = yield call(() =>
        axiosInstance.get(
          crosslistEndpoints.inventory.GET_IMPORT_TABLE_LISTINGS_ENDPOINT({
            marketplace: action.payload.importPlatform as Marketplace,
            ...(MARKETPLACE_PLATFORMS.includes(action.payload.importPlatform as Marketplace) && {
              sendWithMarketplace: true,
            }),
            shop: shopName,
            shopId,
            ...(action.payload.importSearchText && { search: action.payload.importSearchText }),
            ...(action.payload.importNextPageID && { page: action.payload.importNextPageID }),
          })
        )
      );

      const listingsToBeCheckedForImport = shapeListingsForImportCheck(
        response.data.listings,
        action.payload.importPlatform
      );

      yield put(importGetListingsSuccess());
      yield put(setListingsForImport(response.data.listings));
      yield put(importGetInitialStatusRequest(listingsToBeCheckedForImport));
      yield put(setHasMoreImportableListings(response.data.hasMore));
      if (response.data.nextPage) {
        yield put(setImportNextPageID(response.data.nextPage));
      }
    } else {
      if (isPlatformEbay) {
        yield put(
          getEbayProductsRequest({
            platformName: action.payload.importPlatform as unknown as IAPIMarketplaces,
            nextPage: action.payload.importNextPageID,
            importSearchText: action.payload.importSearchText || '',
          })
        );
      }
    }
  } catch (error) {
    const isPlatformEbay = isEbay(action.payload.importPlatform);
    if (error?.statusCode === 401) {
      yield put(setOpenImportDialog(false));
      yield put(
        isPlatformEbay ? setOpenAPIConnectionRequiredDialog(true) : setIsNotLoggedInDialogOpen(true)
      );
    } else if ([400, 502, 520].includes(error?.statusCode)) {
      yield put(setOpenImportDialog(false));
      yield put(setIsImportErrorDialogOpen(true));
    }
    // Handle specific error codes
    yield put(importGetListingsFail(error));
  }
}

function* importListingsSaga(action: PayloadAction<IImportListingsPayload>) {
  try {
    const shopId = getShopperId(
      action.payload.importPlatform as Marketplace,
      action.payload.myShopsState
    );
    yield put(setIsImportingListings(true));
    yield toast.promise(
      axiosInstance.post(crosslistEndpoints.inventory.GET_USER_ACTION_QUEUE_JOB(), {
        action: UserAction.IMPORT,
        listings: action.payload.selectedListings
          .filter((item) => item.id !== null)
          .map((item) => ({
            id: item.id,
            image: item.img,
            title: item.name,
          })),
        product: SubscriptionType.CROSS_LIST,
        actionSource: ActionSource.WEB,
        marketplace: action.payload.importPlatform as Marketplace,
        shop: shopId,
      } as IUserActionQueueJobBase),
      {
        pending: 'Adding importing tasks to queue..',
        success: 'Import tasks added to queue successfully.',
        error: 'Something went wrong',
      },
      { position: 'top-center' }
    );

    yield put(setSelectedImportCheckboxes([]));
    yield put(setIsImportingListings(false));
  } catch (error) {
    toast.error(error, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    yield put(setInProgressList([]));
  }
}

function* apiBulkImportListingsSaga(
  action: PayloadAction<{
    selectedListings: IShopifyProductDetail[];
    selectedImportCheckboxes: string[];
  }>
) {
  try {
    const adjustedListings = action.payload.selectedListings.map((listing) => {
      const { id, img, images, status, name, variants, ...restOfListing } = listing;
      return {
        ...restOfListing,
        image: img || '',
        title: name,
      };
    });

    yield call(() =>
      axiosInstance.post(crosslistEndpoints.inventory.GET_API_BULK_IMPORT(), adjustedListings)
    );

    yield put(apiBulkImportListingsSuccess(action.payload.selectedImportCheckboxes));

    toast.success('Listings successfully imported', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    toast.error(error.message, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    yield put(apiBulkImportListingsFail(error.message));
    yield put(setInProgressList([]));
  }
}

function* importGetInitialStatusSaga(
  action: PayloadAction<{ marketplace: string; listingId: string }[]>
) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(
        crosslistEndpoints.inventory.GET_INITIAL_IMPORT_INFORMATION_OF_LISTINGS(),
        action.payload
      )
    );

    const initialImportStatusList = shapeImportInformationToStringArray(response.data.data);

    yield put(setImportedListingsList(initialImportStatusList));
    yield put(importGetInitialStatusSuccess());
  } catch (error) {
    yield put(importGetInitialStatusFail(error));
  }
}

export function* importTableModuleSaga() {
  yield takeLatest(importGetListingsRequest.type, importGetListingsSaga);
  yield takeLatest(importListingsRequest.type, importListingsSaga);
  yield takeLatest(importGetInitialStatusRequest.type, importGetInitialStatusSaga);
  yield takeLatest(apiBulkImportListingsRequest.type, apiBulkImportListingsSaga);
}
