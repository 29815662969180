import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { IRootState } from 'src/store';

import { PATH_DASHBOARD } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { setIsUserBlockStateDialogOpen } from 'src/store/dashboard/slices/authSlice';

export default function UserBlockStateDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { platform } = useAutomationPlatform();

  const { isUserBlockStateDialogOpen, blockState } = useSelector((state: IRootState) => state.auth);

  const handleClose = () => {
    dispatch(setIsUserBlockStateDialogOpen(false));
  };

  const handleManageSubscriptions = () => {
    const path =
      platform === AutomationPlatform.EBAY
        ? PATH_DASHBOARD.subscription.ebayAutomation
        : PATH_DASHBOARD.subscription.poshmarkAutomation;

    navigate(path);
    handleClose();
  };

  const getBlockStateMessage = () => {
    switch (blockState) {
      case 1:
        return 'Your account is blocked. This seller has already logged in using a different Primelister account. If you think there is something wrong, please send email to support@primelister.com';
      default:
        return 'Your account is blocked. If you think there is something wrong, please send email to support@primelister.com';
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isUserBlockStateDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>Account Blocked</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>{getBlockStateMessage()}</DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleManageSubscriptions}
          sx={{ textTransform: 'none !important' }}
        >
          Manage Subscriptions
        </Button>
      </DialogActions>
    </Dialog>
  );
}
