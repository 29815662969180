import { Stack, Avatar, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { useDispatch, useSelector } from 'react-redux';
import { useCheckSubscriptionStatus } from 'src/hooks/useCheckSubscriptionStatus';
import { IRootState } from 'src/store';
import { getPlatformActions } from 'src/utils/platformActions';
import { getEbayAuthUrlRequest } from 'src/store/automations/ebay/slices/myShopsSlice.ebay';
import { setIsUserBlockStateDialogOpen } from 'src/store/dashboard/slices/authSlice';
import { useBlockState } from 'src/hooks/useBlockState';

const MainConnectButton = () => {
  const dispatch = useDispatch();
  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);

  const { hasCloset, connectedShopsCount, loading } = states.myShops;
  const { poshmarkShopCapacity, ebayShopCapacity } = useSelector(
    (state: IRootState) => state.account
  );
  const { isSubscriptionExpired } = useCheckSubscriptionStatus();
  const { handleBlockState } = useBlockState();

  const handleClosetConnect = () => {
    window.$crisp.push(['do', 'chat:close']);
    
    handleBlockState(() => {
      if (!isSubscriptionExpired) {
        if (platform === AutomationPlatform.EBAY) {
          if (connectedShopsCount < ebayShopCapacity) {
            dispatch(getEbayAuthUrlRequest({ country: 'us' }));
          } else {
            dispatch(platformActions.setIsUpgradeSubscriptionDialogOpen(true));
          }
        } else {
          if (connectedShopsCount < poshmarkShopCapacity) {
            dispatch(platformActions.setIsConnectDialogOpen(true));
          } else {
            dispatch(platformActions.setIsUpgradeSubscriptionDialogOpen(true));
          }
        }
      } else {
        dispatch(platformActions.setShouldOpenExpiredSubscriptionDialog(true));
      }
    });
  };

  const buttonText = hasCloset
    ? platform === AutomationPlatform.EBAY
      ? 'Connect Additional Shops'
      : 'Connect Additional Closets'
    : platform === AutomationPlatform.EBAY
    ? 'Connect My Shop'
    : 'Connect My Closet';

  return (
    <Stack direction="row" spacing={1}>
      <LoadingButton
        loading={platform === AutomationPlatform.EBAY && loading}
        color="primary"
        variant="contained"
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: '4px 16px',
          borderRadius: '8px',
        }}
        onClick={handleClosetConnect}
      >
        <Avatar
          src={`/icons/myClosetIcons/connectIcon.svg`}
          sx={{ fontSize: '1rem', maxWidth: '20px', maxHeight: '20px', mr: 1 }}
        />
        <Typography
          sx={{
            fontSize: '14px !important',
            lineHeight: '24px',
            fontWeight: 700,
          }}
        >
          {buttonText}
        </Typography>
      </LoadingButton>
    </Stack>
  );
};

export default MainConnectButton;
