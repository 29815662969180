import { Automation, UserAction } from '@beta.limited/primelister';
import {
  automationItemsList,
  completedTabItemsList,
  ebayCompletedTabItemsList,
  getItemsList,
  ongoingTabItemsList,
} from '../utils/constants';
import { AutomationPlatform } from 'src/hooks/useAutomationPlatform';

export const getActivityTypeProps = (
  activityType: string,
  isFromCompletedTab?: boolean,
  platform?: AutomationPlatform
) => {
  const isRelist = activityType === 'relist';
  const isAutoRelist = activityType === 'auto-relist';
  const matchValue = isRelist ? Automation.AUTO_RELIST : activityType;

  const matchedActivity = getItemsList(platform!).find((item) => item.value === matchValue);

  const activityIcon = !isFromCompletedTab ? matchedActivity?.autoIcon : matchedActivity?.name;

  const activityDisplayName = isRelist
    ? platform === AutomationPlatform.EBAY
      ? 'Re-create'
      : 'Relist'
    : matchedActivity?.displayName;

  return { activityIcon, activityDisplayName };
};

export const getOngoingActivityTypeProps = (ongoingTaskType: UserAction) => {
  const matchedOngoingTask = ongoingTabItemsList.find((item) => item.value === ongoingTaskType);

  const activityIcon = matchedOngoingTask?.autoIcon;
  const activityDisplayName = matchedOngoingTask?.displayName;
  return { activityIcon, activityDisplayName };
};
