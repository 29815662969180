import { AlertTypes } from './LayoutAlert';

export const getAlertContent = (type: AlertTypes) => {
  switch (type) {
    case AlertTypes.EXTENSION:
      return {
        message:
          'Attention! Extension is not installed on your browser. To use PrimeLister, please install the extension.',
        buttonText: 'Install Extension',
      };
    case AlertTypes.POSHMARK_CONNECTION:
      return {
        message:
          'Attention! Your Poshmark closet is not connected. Connect your closet to use the Poshmark Automation.',
        buttonText: 'Connect My Closet',
      };
    case AlertTypes.POSHMARK_RECONNECTION:
      return {
        message:
          'Attention! Your Poshmark closet disconnected. Re-connect to continue using Poshmark Automation.',
        buttonText: 'Re-connect My Closet',
      };
    case AlertTypes.SUBSCRIPTION_TO_POSHMARK:
      return {
        message:
          'Attention! Automations paused due to your expired subscription. Renew your subscription to restart them.',
        buttonText: 'Renew My Subscription',
      };
    case AlertTypes.EBAY_CONNECTION:
      return {
        message:
          'Attention! Your eBay shop is not connected. Connect your shop to use the eBay Automation.',
        buttonText: 'Connect My Shop',
      };
    case AlertTypes.EBAY_RECONNECTION:
      return {
        message:
          'Attention! Your eBay shop disconnected. Re-connect to continue using eBay Automation.',
        buttonText: 'Re-connect My Shop',
      };
    case AlertTypes.SUBSCRIPTION_TO_EBAY:
      return {
        message:
          'Attention! eBay automations paused due to your expired subscription. Renew your subscription to restart them.',
        buttonText: 'Renew My Subscription',
      };
    case AlertTypes.USER_BLOCK_STATE:
      return {
        message:
          'Attention! Your account has been temporarily blocked. To reactivate it, please renew your subscription by following this',
        linkMessage: 'link.',
      };
    case AlertTypes.MOBILE_DEVICE:
      return {
        message:
          "Attention! Our system has identified that you're using a mobile device. To use PrimeLister, please switch to the Google Chrome browser on your PC/Mac.",
      };
    case AlertTypes.BROWSER_COMPATIBILITY:
      return {
        message:
          "Notice: To use our Cross-listing Tool, please switch to the Chrome browser. It's required for our extension to work properly.",
        buttonText: 'Install Chrome',
      };
    default:
      return null;
  }
};
