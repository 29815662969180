import { forwardRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, BoxProps, Typography, Stack } from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';

import SwitchToolsPopover from 'src/layouts/SwitchToolsPopover';

import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import useOnboarding from 'src/utils/onBoardingSteps/useOnboarding';
import { UserTagTypesEnum } from 'src/store/automations/slices/automationsSlice';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const { isUserBoardedPoshmarkAutomation } = useSelector(
      (state: IRootState) => state.automations
    );

    const { driverObject } = useOnboarding(UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION);

    useEffect(() => {
      if (!isUserBoardedPoshmarkAutomation) {
        driverObject.drive();
      }
    }, [isUserBoardedPoshmarkAutomation]);

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          height: 40,
          display: 'inline-flex',
          ...sx,
          alignItems: 'center',
          cursor: 'default',
        }}
        {...other}
      >
        <SwitchToolsPopover />
        <Stack direction="row" alignItems="center" sx={{ cursor: 'pointer' }}>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M24.3239 10.1652C24.7499 9.7391 25.0878 9.23329 25.3183 8.67663C25.5488 8.11996 25.6674 7.52335 25.6674 6.92084C25.6673 6.31834 25.5486 5.72174 25.318 5.16512C25.0874 4.6085 24.7494 4.10275 24.3233 3.67676C23.8972 3.25076 23.3914 2.91285 22.8348 2.68234C22.2781 2.45182 21.6815 2.3332 21.079 2.33325C19.8622 2.33336 18.6952 2.81684 17.8349 3.67734L16.8 4.71217L16.8444 4.84167C17.3547 6.30095 18.1896 7.62534 19.2862 8.715C20.4082 9.84383 21.7786 10.6948 23.2879 11.2L24.3239 10.1652Z"
              fill="#2065D1"
            />
            <path
              d="M16.8455 4.66669L16.8 4.71102L16.8444 4.84169C17.3547 6.30097 18.1896 7.62536 19.2862 8.71502C20.4082 9.84388 21.7785 10.6949 23.2879 11.2L13.3012 21.1867C12.6269 21.8599 12.2897 22.197 11.9187 22.4864C11.4808 22.8277 11.0071 23.1203 10.5059 23.359C10.0812 23.562 9.6297 23.7125 8.72553 24.0135L3.96203 25.6014C3.74405 25.6744 3.51003 25.6852 3.28625 25.6325C3.06247 25.5799 2.85779 25.466 2.69518 25.3035C2.53257 25.141 2.41847 24.9364 2.36568 24.7127C2.3129 24.4889 2.32352 24.2549 2.39636 24.0369L3.98536 19.2722C4.28636 18.3692 4.43686 17.9177 4.6387 17.493C4.87786 16.9914 5.1707 16.5177 5.51253 16.079C5.80186 15.708 6.13903 15.372 6.8122 14.6989L16.8455 4.66669Z"
              fill="#2065D1"
            />
            <path
              opacity="0.5"
              d="M6.40725 4.71915L4.71909 6.40731C3.12892 7.99748 2.33325 8.79315 2.33325 9.78131C2.33325 10.7695 3.12892 11.5663 4.71909 13.1565L6.53675 14.9741L6.81209 14.6988L14.9741 6.53681L13.1564 4.71915C11.5651 3.12898 10.7683 2.33331 9.78125 2.33331C8.79425 2.33331 7.99742 3.12898 6.40725 4.71915ZM21.4619 13.0246L13.2999 21.1866L13.0246 21.462L14.8446 23.2808C16.4336 24.871 17.2281 25.6666 18.2174 25.6666C19.2056 25.6666 20.0013 24.871 21.5914 23.2808L23.2796 21.5926C24.8709 20.0025 25.6666 19.2068 25.6666 18.2186C25.6666 17.2293 24.8709 16.4336 23.2808 14.8435L21.4619 13.0246Z"
              fill="#2065D1"
            />
            <path
              d="M23.4594 15.0232L22.0955 16.3882C21.9411 16.5542 21.8572 16.7737 21.8614 17.0004C21.8656 17.227 21.9577 17.4432 22.1181 17.6034C22.2786 17.7636 22.495 17.8552 22.7216 17.859C22.9483 17.8628 23.1676 17.7784 23.3334 17.6237L24.675 16.282C24.2816 15.8512 23.8766 15.431 23.4605 15.022L23.4594 15.0232ZM11.3937 3.04852L9.84554 4.59552C9.76426 4.6768 9.69979 4.77329 9.6558 4.87948C9.61181 4.98568 9.58917 5.0995 9.58917 5.21444C9.58917 5.32938 9.61181 5.4432 9.6558 5.5494C9.69979 5.65559 9.76426 5.75208 9.84554 5.83336C9.92681 5.91463 10.0233 5.97911 10.1295 6.02309C10.2357 6.06708 10.3495 6.08972 10.4645 6.08972C10.5794 6.08972 10.6932 6.06708 10.7994 6.02309C10.9056 5.97911 11.0021 5.91463 11.0834 5.83336L12.6759 4.24202C12.2679 3.82383 11.8396 3.42591 11.3925 3.04969L11.3937 3.04852Z"
              fill="#2065D1"
            />
          </svg>
          <Typography
            sx={{
              fontSize: '14px !important',
              fontWeight: '700 !important',
              lineHeight: '22px !important',
              color: 'black',
              ml: '10px !important',
            }}
          >
            Cross-listing
          </Typography>
        </Stack>
      </Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link to={PATH_DASHBOARD.root} component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
