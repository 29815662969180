import { Automation, PoshmarkSettings } from '@beta.limited/primelister';
import { IRootState } from '../../store/index';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from '../../routes/paths';
import { driver } from 'driver.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  UserTagTypesEnum,
  setIsAutomationOptionDrawerOpen,
  setIsOnBoardingActive,
  setSelectedAutomationOption,
  setUserTagsRequest,
  setActiveOnboardingType,
} from 'src/store/automations/slices/automationsSlice';

import { isLoggedIn } from '../isLoggedIn';

import { setShouldOpenSwitchPopoverForBoarding } from 'src/store/dashboard/slices/homeSlice';
import { hideCrispChat, showCrispChat } from '../crispChatHelpers';
import { setIsInFullscreenMode } from 'src/store/dashboard/slices/inventorySlice';
import { AutomationPlatform, useAutomationPlatform } from 'src/hooks/useAutomationPlatform';

const onBoardingAutomation = {
  name: Automation.AUTO_FOLLOW_NEW_CLOSETS as keyof PoshmarkSettings,
  url: '/icons/automationIcons/followFreshClosets.svg',
  displayName: 'Follow New Closets',
  index: 7,
  tooltipText:
    'PrimeLister will automatically follow new Poshers from your Poshmark “Feed“ on a daily basis.',
};

const useOnboarding = (choosenType: UserTagTypesEnum) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = isLoggedIn();
  const { isOnBoardingActive, activeOnboardingType } = useSelector(
    (state: IRootState) => state.automations
  );
  let onboardingType: UserTagTypesEnum | '' = '';
  if (isOnBoardingActive) {
    onboardingType = activeOnboardingType;
  } else {
    onboardingType = choosenType;
  }
  const { platform } = useAutomationPlatform();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSwitchToolFirstStep = () => {
    dispatch(setIsOnBoardingActive(true));
    dispatch(setShouldOpenSwitchPopoverForBoarding(true));
  };
  const handleSwitchToolSecondStep = () => dispatch(setShouldOpenSwitchPopoverForBoarding(true));
  const handleSwitchToolThirdStep = () => {
    switch (onboardingType) {
      case UserTagTypesEnum.ONBOARDING_EBAY_AUTOMATION:
        navigate(PATH_AUTOMATIONS.ebayMyShops);
        break;
      case UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION:
        navigate(PATH_AUTOMATIONS.myCloset);
        break;
    }
    dispatch(setShouldOpenSwitchPopoverForBoarding(false));
  };

  const handleSwitchToolLastStep = () => {
    switch (onboardingType) {
      case UserTagTypesEnum.ONBOARDING_EBAY_AUTOMATION:
        navigate(PATH_AUTOMATIONS.poshmarkRoot);
        break;
      case UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION:
        navigate(PATH_DASHBOARD.root);
        break;
    }
  };

  const getHandlers = () => {
    switch (onboardingType) {
      case UserTagTypesEnum.ONBOARDING_MULTIPLE_CLOSETS:
        const handleMultipleClosetFirstStep = () => {
          dispatch(setIsOnBoardingActive(true));
          navigate(PATH_AUTOMATIONS.automations);
        };
        const handleMultipleClosetFourthStep = () =>
          setTimeout(
            () =>
              window.scroll({
                top: 0,
                left: 0,
              }),
            100
          );
        return {
          handleMultipleClosetFirstStep,
          handleMultipleClosetFourthStep,
        };
      case UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION:
        return {
          handleSwitchToolFirstStep,
          handleSwitchToolSecondStep,
          handleSwitchToolThirdStep,
          handleSwitchToolLastStep,
        };
      case UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION_FEATURES:
        const handleFeaturesFirstStep = () => dispatch(setIsOnBoardingActive(true));

        const handleFeaturesSecondStep = () => {
          dispatch(setSelectedAutomationOption(onBoardingAutomation));
          dispatch(setIsAutomationOptionDrawerOpen(true));
          hideCrispChat();
        };

        const handleFeaturesFourthStep = () => {
          navigate(PATH_AUTOMATIONS.activity);
          showCrispChat();
          dispatch(setIsAutomationOptionDrawerOpen(false));
        };

        const handleFeaturesLastStep = () => navigate(PATH_AUTOMATIONS.automations);
        return {
          handleFeaturesFirstStep,
          handleFeaturesSecondStep,
          handleFeaturesFourthStep,
          handleFeaturesLastStep,
        };
      case UserTagTypesEnum.ONBOARDING_EBAY_AUTOMATION:
        return {
          handleSwitchToolFirstStep,
          handleSwitchToolSecondStep,
          handleSwitchToolThirdStep,
          handleSwitchToolLastStep,
        };
      default:
        return {};
    }
  };

  const onboardingSteps: {
    [key: string]: any[];
  } = {
    [UserTagTypesEnum.ONBOARDING_MULTIPLE_CLOSETS]: [
      {
        element: '.multipleNavItem',
        popover: {
          title: 'Navigate to the Automations Tab',
          description:
            "Click the 'Automations' tab at the top to explore settings specific to each of your closets.",
          side: 'top',
          align: 'center',
          showButtons: ['next'],
        },
        onHighlightStarted: getHandlers().handleMultipleClosetFirstStep,
      },
      {
        element: '.multipleClosetSelectMenu',
        popover: {
          title: 'Switching Between Closets',
          description: 'See all your connected closets here and switch between them easily!',
          side: 'top',
          align: 'center',
          showButtons: ['next', 'previous'],
        },
      },
      {
        element: '.multipleClosetDropdown',
        popover: {
          title: 'Select Your Closet',
          description:
            "Now, select which closet you'd like to configure. Upon selection, the settings for that specific closet will appear.",
          side: 'right',
          align: 'start',
          showButtons: ['next', 'previous'],
        },
      },
      {
        element: '.multipleClosetAutomationContainer',
        popover: {
          title: 'Customize Your Closet Automations',
          description:
            'You can set automation rules differently for each closet. To apply them, make sure you activate them separately for each closet.',
          side: 'left',
          align: 'start',
          showButtons: ['next', 'previous'],
        },
        onHighlightStarted: getHandlers().handleMultipleClosetFourthStep,
      },
      {
        popover: {
          title: 'End of Tour',
          description:
            "That's it! You've successfully completed the tour. Now you know how to set up and activate & edit settings for each of your multiple closets.",
          side: 'top',
          align: 'center',
          showButtons: ['next'],
        },
      },
    ],
    [UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION]: [
      {
        element: '.switch-driver',
        popover: {
          title: 'Switching Between Products',
          description:
            'Click the button at the top left. See all your products here and switch easily!',
          side: 'right',
          align: 'start',
          showButtons: ['next'],
        },
        onHighlightStarted: getHandlers().handleSwitchToolFirstStep,
        onDeselected: () => dispatch(setShouldOpenSwitchPopoverForBoarding(true)),
      },
      {
        element: '.switch-menu-driver',
        popover: {
          title: 'Switching Between Products',
          description: 'Easily navigate between your products with just one click!',
          side: 'right',
          align: 'start',
          showButtons: ['next'],
        },
        onHighlightStarted: getHandlers().handleSwitchToolSecondStep,
        onDeselected: () => dispatch(setShouldOpenSwitchPopoverForBoarding(false)),
      },
      {
        popover: {
          title: 'Check Poshmark Automation',
          description: "For instance, you're now viewing the Poshmark Automation product.",
          side: 'bottom',
          align: 'start',
          showButtons: ['next', 'previous'],
        },
        onHighlightStarted: getHandlers().handleSwitchToolThirdStep,
      },
      {
        popover: {
          title: 'End of Tour',
          description:
            "That's it! You've successfully completed the tour. Now you know how to switch between your products easily.",
          side: 'top',
          align: 'center',
          showButtons: ['next'],
        },
        onHighlightStarted: getHandlers().handleSwitchToolLastStep,
      },
    ],
    [UserTagTypesEnum.ONBOARDING_EBAY_AUTOMATION]: [
      {
        element: '.switch-driver',
        popover: {
          title: 'Switching Between Products',
          description:
            'Click the button at the top left. See all your products here and switch easily!',
          side: 'right',
          align: 'start',
          showButtons: ['next'],
        },
        onHighlightStarted: getHandlers().handleSwitchToolFirstStep,
        onDeselected: () => dispatch(setShouldOpenSwitchPopoverForBoarding(true)),
      },
      {
        element: '.switch-menu-driver',
        popover: {
          title: 'Switching Between Products',
          description: 'Easily navigate between your products with just one click!',
          side: 'right',
          align: 'start',
          showButtons: ['next'],
        },
        onHighlightStarted: getHandlers().handleSwitchToolSecondStep,
        onDeselected: () => dispatch(setShouldOpenSwitchPopoverForBoarding(false)),
      },
      {
        popover: {
          title: 'Check eBay Automation',
          description: "For instance, you're now viewing the eBay Automation product.",
          side: 'bottom',
          align: 'start',
          showButtons: ['next', 'previous'],
        },
        onHighlightStarted: getHandlers().handleSwitchToolThirdStep,
      },
      {
        popover: {
          title: 'End of Tour',
          description:
            "That's it! You've successfully completed the tour. Now you know how to switch between your products easily.",
          side: 'top',
          align: 'center',
          showButtons: ['next'],
        },
        onHighlightStarted: getHandlers().handleSwitchToolLastStep,
      },
    ],
    [UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION_FEATURES]: [
      {
        element: '.driver-first',
        popover: {
          title: 'Accessing Automation Settings',
          description: 'You can change the automation settings here.',
          side: 'top',
          align: 'center',
          showButtons: ['next'],
        },
        onHighlightStarted: getHandlers().handleFeaturesFirstStep,
      },
      {
        element: '.driver-second',
        popover: {
          title: 'Enabling Automation',
          description: 'Turn on your automation with this button.',
          side: 'top',
          align: 'center',
          showButtons: ['next', 'previous'],
        },
        onHighlightStarted: getHandlers().handleFeaturesSecondStep,
      },
      {
        element: '.driver-third',
        popover: {
          title: 'Closing the Automation Menu',
          description: "Click the 'X' to close the automation settings anytime.",
          side: 'bottom',
          align: 'start',
          showButtons: ['next', 'previous'],
        },
      },
      {
        element: '.driver-fourth',
        popover: {
          title: 'Exploring the Activity Page',
          description: 'This is where you can see all the records of your automation tasks.',
          side: 'bottom',
          align: 'center',
          showButtons: ['next', 'previous'],
        },
        onHighlightStarted: getHandlers().handleFeaturesFourthStep,
      },
      {
        popover: {
          title: 'End of Tour',
          description:
            "That's it! You've successfully completed the tour. Remember, you can manage all other automations just like this.",
          side: 'top',
          align: 'center',
          showButtons: ['next'],
        },
        onHighlightStarted: getHandlers().handleFeaturesLastStep,
      },
    ],
    [UserTagTypesEnum.ONBOARDING_INVENTORY_FULLSCREEN]: [
      {
        element: '.fullscreenModeButton',
        popover: {
          title: 'Expanding Your Inventory View',
          description:
            'Boost your product visibility optimally by clicking the button to access a fullscreen inventory panel.',
          side: 'bottom',
          align: 'center',
          showButtons: ['next'],
          onNextClick: () => {
            dispatch(setIsOnBoardingActive(true));
            dispatch(setIsInFullscreenMode(true));
            driverObject.moveNext();
            driverObject.refresh();
          },
        },
      },
      {
        element: '.fullscreenModeButton',
        popover: {
          title: 'Exiting Full-Screen Mode ',
          description:
            'Effortlessly revert to the standard view with a simple click on the button.',
          side: 'bottom',
          align: 'center',
          showButtons: ['next', 'previous'],
          onNextClick: () => {
            dispatch(setIsInFullscreenMode(false));
            driverObject.moveNext();
          },
        },
      },
      {
        popover: {
          title: 'End of Tour',
          description: "That's it! You've successfully completed the tour.",
          side: 'top',
          align: 'center',
          showButtons: ['next'],
        },
      },
    ],
    [UserTagTypesEnum.MY_SHOPS_API_ONBOARDING]: [
      {
        element: '.myShopsAPITable',
        popover: {
          title: 'Explore Our API Platforms',
          description: 'Browse our supported platforms for official API integrations.',
          side: 'bottom',
          align: 'center',
          showButtons: ['next'],
          onNextClick: () => {
            driverObject.moveNext();
            driverObject.refresh();
          },
        },
        onHighlightStarted: () => dispatch(setIsOnBoardingActive(true)),
      },
      {
        element: '.connectToAPIButton',
        popover: {
          title: 'Connect to Platform',
          description:
            'Choose your platform, click this action button, follow the instructions, and grant permissions.',
          side: 'bottom',
          align: 'center',
          showButtons: ['next', 'previous'],
          onNextClick: () => {
            driverObject.moveNext();
          },
        },
      },
      {
        popover: {
          title: 'End of Tour',
          description: 'API Connection process is complete.',
          side: 'top',
          align: 'center',
          showButtons: ['next'],
        },
      },
    ],
  };
  const steps = onboardingSteps[onboardingType as UserTagTypesEnum];

  const driverObject = driver({
    onDestroyed: () => {
      dispatch(setIsOnBoardingActive(false));
      dispatch(setShouldOpenSwitchPopoverForBoarding(false));
      showCrispChat();
      dispatch(setActiveOnboardingType('' as UserTagTypesEnum));
      if (onboardingType === UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION) {
        searchParams.delete('isOnboarding');
        setSearchParams(searchParams);
      }
    },
    stagePadding: 2,
    popoverClass: 'driverjs-theme',
    prevBtnText: 'Skip',
    onPrevClick: () => driverObject.destroy(),
    onHighlighted: () => {
      dispatch(setIsOnBoardingActive(true));
      dispatch(setActiveOnboardingType(onboardingType as UserTagTypesEnum));
      // Only set user tag if its not the my shops api onboarding
      if (onboardingType !== UserTagTypesEnum.MY_SHOPS_API_ONBOARDING) {
        dispatch(
          setUserTagsRequest({
            userId,
            userTagType: onboardingType as UserTagTypesEnum,
          })
        );
      }
    },
    steps,
  });
  return { driverObject };
};
export default useOnboarding;
